import React from 'react'
import { Avatar } from '@mui/material';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

interface TypingProps {
  avatarFilename: string
  loadingBar: boolean
}

export const Typing: React.FC<TypingProps> = ({ avatarFilename, loadingBar }) => {
  const array: Array<string> = ['T', 'y', 'p', 'i', 'n', 'g', '.', '.', '.'];
  const delayTime = Math.random() + 2;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setInterval(() => {
      setIsVisible(true);
      clearInterval(timeout);
    }, delayTime * 1000);
    // Clean up the timeout when the component unmounts or when the dependency changes
    return () => clearInterval(timeout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isVisible && (
        <label htmlFor="avatar">
          <Avatar
            alt="Avatar"
            src={`/images/${avatarFilename}.webp`}
            sx={{ width: 24, height: 24, cursor: 'pointer' }}
            className="avatar"
          />
        </label>
      )}
      <div className="typing">
        {array.map((char, index) => (
          <span
            key={`char-${index}`}
            className="typing__dot"
            style={{
              animationDelay: `${delayTime + 0.1 * index}s`,
            }}
          >
            {char}
          </span>
        ))}
        
      </div>
      <div style={{
        display: 'flex',
        alignItems: 'flex-end',
        marginLeft: 8
      }}>{isVisible && loadingBar && <CircularProgress size={18} />}</div>
    </>
  );
};
