import './App.css';
import React, { ForwardedRef } from 'react';
import { Routes, Route, useLocation, Location } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import SignUp from './Pages/SignUp';
import SignIn from './Pages/SignIn';
import Navbar from './Components/Navbar';
import { PrivateRoute } from './Components/PrivateRoute';
import Dashboard from './Pages/Dashboard';
import ChatLogList from './Pages/ChatLogList';
import Analytics from './Pages/Analytics';
import Apis from './Pages/Apis';
import ChatbotTabs from './Pages/ChatbotTabs';
import { Grid } from '@mui/material';
import ChatbotInIframe from './Pages/ChatbotInIframe';
import { TokenUsage } from './Components/Tables/TokenUsage';
import { useCallback, useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from './Slice/chatbotSlice';
import Slide from '@mui/material/Slide';

const Alert = React.forwardRef(function Alert(
  props: AlertProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const defaultTheme = createTheme();

function App() {
  const dispatch = useDispatch();
  const isInframe = window.self !== window.top;
  const [open, setOpen] = useState<boolean>(true);
  const chatbot = useSelector((state: any) => state.chatbot);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [drawerWidth, setDrawerWidth] = useState<Number>(250);
  const location: Location = useLocation();
  const pathName = location.pathname;
  const handleClose = () => {
    setAlertOpen(false);
    dispatch(setMessage({
      code: 200
    }));
  };
  const toggleDrawer = useCallback(() => {
    if (open) {
      setDrawerWidth(80);
    } else {
      setDrawerWidth(250);
    }
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    if (chatbot.message) {
      setAlertOpen(chatbot.message.code === -1);
    }
  }, [chatbot.message]);

  if (isInframe) {
    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={alertOpen}
          onClose={handleClose}
          autoHideDuration={3000}
          message="I love snacks"
          key={'bottom right'}
          TransitionComponent={Slide}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            {chatbot.message?.text}
          </Alert>
        </Snackbar>
        <Routes>
          <Route
            path="/chatbot/:chatbotId"
            element={<ChatbotInIframe />}
          />
        </Routes>
      </>
    );
  }
  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <CssBaseline />
          {!isInframe && <Navbar toggleDrawer={toggleDrawer} open={open} />}
          <Grid
            container
            spacing={2}
            sx={{
              ml: `${
                ['/sign-in', '/sign-up'].includes(pathName) ? 0 : drawerWidth
              }px`,
            }}
          >
            <Grid item xs={12}>
              <Container
                sx={{ mt: 4, mb: 4 }}
                style={{ maxWidth: '100%', padding: '0px' }}
              >
                <div className="App" id="App">
                  <div className="App-Content">
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <PrivateRoute>
                            <Dashboard />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/chatbot/:chatbotId/:tab/:chatLogId?"
                        element={
                          <PrivateRoute>
                            <ChatbotTabs />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/analytics"
                        element={
                          <PrivateRoute>
                            <Analytics />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/apis"
                        element={
                          <PrivateRoute>
                            <Apis />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/api-usage"
                        element={
                          <PrivateRoute>
                            <TokenUsage />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="/chat-log"
                        element={
                          <PrivateRoute>
                            <ChatLogList />
                          </PrivateRoute>
                        }
                      />
                      <Route path="/sign-in" element={<SignIn />} />
                      <Route path="/sign-up" element={<SignUp />} />
                    </Routes>
                  </div>
                </div>
              </Container>
              {/* </Box> */}
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
      {/* <div className="App">
        <Navbar />
        
      </div> */}
    </>
  );
}
export default App;
