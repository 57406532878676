import React, { useCallback, useEffect, useState } from "react";
import {
  Form,
  Modal,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
} from "react-bootstrap";
import { postWithAuthentication, SendRequestAPIWithTokenByMethod } from "../../Utils/FetchUtil";
import StringsData from '../../Constants/strings.json';
import { Switch } from "@mui/material";
import { useParams } from 'react-router-dom';

interface StringModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  isNew: boolean
  handleGet: () => void
  initValue?: any
  id?: string
}

export const StringModal: React.FC<StringModalProps> = ({ show, setShow, isNew, handleGet, initValue, id }) => {
  const { chatbotId } = useParams();
  const [stringPoolId, setStringPoolId] = useState<string>(StringsData[0].id);
  const [testId, setTestId] = useState<string>("");
  const [body, setBody] = useState<string>("");
  const [weight, setWeight] = useState<number>(0)
  const [isRetired, setIsRetired] = useState(false)

  useEffect(() => {
    if (show) {
      if (isNew) {
        setStringPoolId(StringsData[0].id)
        setBody("")
        setWeight(0)
        setTestId("")
      }
      else if (initValue) {
        const {StringPoolId, Body, Weight, Retired} = initValue
        const index = StringsData.findIndex((item) => item.displayName === StringPoolId)
        setStringPoolId(index === -1 ? "Unknown" : StringsData[index].id)
        setBody(Body)
        setWeight(Weight)
        setIsRetired(Retired)
      }
    }
  }, [initValue, isNew, show])

  const handleClose = useCallback(() => {
    setShow(false);
  }, [setShow]);

  const handleSubmit = useCallback(
    (ev) => {
      ev.preventDefault();
      if (isNew) {
        requestAddNew()
      } else {
        requestUpdateOne()
      }
      setShow(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isNew, setShow, stringPoolId, weight, body, isRetired, testId]
  );

  const requestAddNew = () => {
    postWithAuthentication(
      `string/${chatbotId}`,
      {
        body: JSON.stringify({
          stringPoolId,
          testId,
          body,
          weight,
        }),
      },
      (result) => {
        handleGet()
      }
    );
  };

  const requestUpdateOne = () => {
    SendRequestAPIWithTokenByMethod(
      'PUT',
      `string/${chatbotId}/${id}`,
      {
        body: JSON.stringify({
          weight,
          retired: isRetired
        }),
      },
      (result) => {
        handleGet()
      }
    );
  };

  const handleStringIDChange = useCallback((ev) => {
    setStringPoolId(ev.target.value);
  }, [setStringPoolId]);

  const handleBodyChange = useCallback((ev) => {
    setBody(ev.target.value);
  }, [setBody]);

  const handleWeightChange = useCallback((ev) => {
    setWeight(Number(ev.target.value));
  }, [setWeight]);

  const handleTestIDChange = useCallback((ev) => {
    setTestId(ev.target.value);
  }, [setTestId]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      container={document.getElementById("App")}
      style={{ position: "absolute", height: 650 }}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{isNew ? "Add New String" : "Edit String"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isNew && <FormGroup>
            <FormLabel style={{marginTop: 8}}>String Pool ID</FormLabel>
            <Form.Select value={stringPoolId} onChange={handleStringIDChange}>
              {
                StringsData.map((string) => <option key={string.id} value={string.id}>{string.displayName}</option>)
              }
            </Form.Select>
          </FormGroup>}
          {isNew && <FormGroup>
            <FormLabel style={{marginTop: 8}}>Test ID</FormLabel>
            <FormControl
              value={testId}
              type="text"
              placeholder="Enter text"
              name="name"
              onChange={handleTestIDChange}
            />
          </FormGroup>}
          {isNew && <FormGroup>
            <FormLabel style={{marginTop: 8}}>Body</FormLabel>
            <FormControl
              as="textarea"
              rows={5}
              value={body}
              placeholder="Enter text"
              name="name"
              onChange={handleBodyChange}
            />
          </FormGroup>}
          <FormGroup>
            <FormLabel style={{marginTop: 8}}>Weight</FormLabel>
            <FormControl
              value={weight}
              type="number"
              placeholder="Enter text"
              name="name"
              onChange={handleWeightChange}
            />
          </FormGroup>
          {!isNew && <FormGroup>
            <FormLabel style={{marginTop: 8}}>Retired</FormLabel>
            <Switch checked={isRetired} onClick={() => setIsRetired(!isRetired)}/>
          </FormGroup>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            {isNew ? "Add" : "Update"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
