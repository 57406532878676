import React, { useEffect, useState } from "react";
import AnalyticsCard from "./AnalyticsCard";
import { getFullUrl, getRequestInit } from "../../Utils/FetchUtil";
import { FilterOptionProp } from "../../Pages/Analytics";
interface TotalSessionCountCardProps {
  botId: string,
  filterOption: FilterOptionProp
}

const TotalImpressionsCard: React.FC<TotalSessionCountCardProps> = ({ botId, filterOption }) => {
  const [val, setVal] = useState<number>(0);
  useEffect(() => {
    if (botId === null) return
    if (!filterOption) return
    if (!filterOption.startDate || !filterOption.endDate) return
    (async () => {
      const startDate = encodeURIComponent(filterOption.startDate)
      const endDate = encodeURIComponent(filterOption.endDate)
      const response = await fetch(
        getFullUrl(
          `chatbot/${botId}/impressions/count?startDate=${startDate}&endDate=${endDate}`),
        {
          ...getRequestInit(),
          method: "GET",
        })
      const result: number = await response.json()
      console.log(result)
      setVal(result)
    })()
  }, [botId, filterOption]);
  return (
    <AnalyticsCard text="Impressions" val={val} icon="fa-message" />
  );
};

export default TotalImpressionsCard;


