export const codeDetails = [
    {
        "code": "openingLine1",
        "description": "From what I understand, you need one of our top services from the $PROVIDER category …"
    },
    {
        "code": "openingLine2",
        "description": "Sure thing, the best advice for $PROVIDER, as recommended by Hostadvice users, are …"
    },
    {
        "code": "openingLine3",
        "description": "Definitely! The top recommendation for $PROVIDER, according to Hostadvice users, are …"
    },
    {
        "code": "openingLine4",
        "description": "No problem at all! For $PROVIDER, the best advice from Hostadvice users are …"
    },
    {
        "code": "openingLine5",
        "description": "Certainly! The prime suggestion for $PROVIDER, endorsed by Hostadvice users, are …"
    },
    {
        "code": "openingLine1403A",
        "description": "Abandoned test."
    },
    {
        "code": "openingLine1403B",
        "description": "Abandoned test."
    },
    {
        "code": "oneShot",
        "description": "Propose one item from the toplist."
    },
    {
        "code": "twoShot",
        "description": "Propose two items from the toplist."
    },
]
