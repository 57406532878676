import { ReactNode, useMemo } from "react";
import { useSelector } from "react-redux";

interface ChatbotProps {
  name?: ReactNode;
  _id?: string;
  voiceId?: string;
}

export const useChatbot = ({ chatbotId }) => {
  const data: Array<ChatbotProps> = useSelector((auth: any) => auth.chatbot.chatbots);
  const index = useMemo(
    () => data.findIndex((row) => row._id === chatbotId),
    [chatbotId, data]
  );
  if (index !== -1) return data[index];
  return {};
};
