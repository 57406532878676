import React from 'react'
import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface CopyButtonProps {
  onClick: () => {};
  title?: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ onClick = () => {}, title = "Copy" }) => {
  return (
    <>
      <IconButton color="primary" onClick={onClick} title={title}>
        <ContentCopyIcon />
      </IconButton>
    </>
  );
};

export default CopyButton;
