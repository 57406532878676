import React, { useEffect, useRef, useCallback, useState } from 'react';
import {
  postWithAuthentication,
  getFullUrl,
  getRequestInit,
} from '../Utils/FetchUtil';
import { useDispatch } from 'react-redux';
import {
  detectBrowser,
  detectDevice,
  detectOS,
  getLocation,
} from '../Utils/DetectUtil';
import '../Styles/chatbot.css';
import { Box, Slide, Typography } from '@mui/material';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ChatBubbleOutlinedIcon from '@mui/icons-material/ChatBubbleOutlined';
import ChatboxCard from './ChatboxCard';
import { useLocation } from 'react-router-dom';

interface Message {
  content: string;
  role: string;
  date?: string;
  source?: string;
  tableData?: any;
  audio?: any;
  isStarted?: boolean;
  isPlaying?: boolean;
}

interface ChatboxProps {
  name?: string;
  messages?: Array<Message>;
  setMessages?: React.Dispatch<React.SetStateAction<Array<Message>>>;
  readOnly?: boolean;
  logId: string;
  botId?: string;
  chatbot?: any;
  defaultOptions?: Array<string>;
  avatarName?: string;
  avatarImageId?: string;
  appearedOn?: string;
  timeFlagArr?: Array<boolean>;
  setTimeFlagArr?: React.Dispatch<React.SetStateAction<Array<boolean>>>;
  url: string;
}

const Chatbox: React.FC<ChatboxProps> = ({
  name,
  messages = [],
  setMessages,
  readOnly = false,
  logId,
  botId = '',
  chatbot,
  defaultOptions = [],
  appearedOn = '',
  timeFlagArr = [false],
  setTimeFlagArr,
  url
}) => {
  const dispatch = useDispatch();
  const isInframe = window.self !== window.top;
  const positionParam = new URL(window.location.href).searchParams.get(
    'position'
  );
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const isShow: string = searchParams.get('isShow');
  const isMinimized: string = searchParams.get('isMinimized');
  const chatboxRef = useRef(null);
  const msgRef = useRef(null);
  const messagesRef = useRef(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [text, setText] = useState('');
  const [height, setHeight] = useState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initHeight, setInitHeight] = useState();
  // --- Begin Audio Relative State ---
  const [isHover, setIsHover] = useState<boolean>(false);
  // --- End Audio Relative State ---
  const [messageBoxHeight, setMessageBoxHeight] = useState<number>(30);
  const [urlPresented, setUrlPresented] = useState<string | null>(null)

  function hashCode(str: string) {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i)
        hash = ((hash << 5) - hash) + char
        hash |= 0 // Convert to 32bit integer
    }
    return hash
  }
  const bucketPrime = Math.abs(hashCode(logId))
  const personas = [
    {
      avatarFilename: "avatar_alfa",
      avatarName: "Alia",
      testCodes: ["personaAlia"]
    },
    {
      avatarFilename: "avatar_bravo",
      avatarName: "Bruce",
      testCodes: ["personaBruce"]
    },
    {
      avatarFilename: "avatar_charlie",
      avatarName: "Nina",
      testCodes: ["personaNina"]
    },
    {
      avatarFilename: "avatar_delta",
      avatarName: "Miller",
      testCodes: ["personaMiller"]
    }
  ]
  const bucket = bucketPrime % personas.length
  const { avatarFilename, avatarName, testCodes } = personas[bucket]

  console.log({
    bucketPrime,
    bucket,
    avatarFilename,
    avatarName,
    testCodes
  
  })

  useEffect(() => {
    const urlChanged = url !== urlPresented
    if (toggle && isInframe && urlChanged) {
      setUrlPresented(url)
    }
  }, [
    toggle,
    url,
    urlPresented,
    isInframe
  ])

  useEffect(() => {
    if (botId && urlPresented) {
      fetch (
        getFullUrl(`chatbot/${botId}/impression`),
        {
          ...getRequestInit(),
          body: JSON.stringify({
            url: urlPresented,
            logId,
            testCodes
          })
        })
    }
  }, [botId, logId, urlPresented, testCodes])

  //-----------To Measure Response Time--------------
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sendTime, setSendTime] = useState<Date>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [receiveTime, setReceiveTime] = useState<Date>();
  //-------------------------------------------------
  const [intervalId, setIntervalId] = useState<Array<number | NodeJS.Timeout>>(
    []
  );
  const [inited, setInited] = useState<boolean>(false);

  useEffect(() => {
    if (isMinimized === 'false') {
      setToggle(true);
    } else if (isMinimized === 'true') {
      setToggle(false);
    }
  }, [isMinimized]);

  useEffect(() => {
    if (process.env.REACT_APP_CHAT_AUTOMATIC_POPUP_ENABLED && isInframe) {
      if (!inited) {
        setInited(() => true);
        if (chatbot?.delayTime) {
          const time = chatbot?.delayTime * 1000;
          const id = setInterval(() => {
            setToggle(true);
            // eslint-disable-next-line no-restricted-globals
            parent.postMessage(
              {
                type: 'AITechlyChatWindowOpenState',
                isOpen: true,
              },
              '*'
            );
            postWithAuthentication(
              `chatlog/add-event/${logId}`,
              {
                body: JSON.stringify({
                    type: 'automaticOpen',
                  })
              },
              null,
              null,
              true)
            clearInterval(id);
          }, time);
          setIntervalId((prevIntervalId) => [...prevIntervalId, id]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inited]);

  useEffect(() => {
    if (intervalId.length === 2) {
      clearInterval(intervalId[0]);
      setIntervalId((prev) => [prev[1]]);
    }
  }, [intervalId]);

  const resetChatBox = () => {
    sessionStorage.clear();
    setMessages([]);
    setIsGenerating(false);
  };

  const sendMessage = useCallback(
    async (msg) => {
      // Check if the key pressed is the Enter key
      const sourcelink_prefix = '<sourcelink>';
      const table_prefix = '<api-table>';
      const assistantMessage = {
        content: '...',
        role: 'assistant',
      };
      const date = new Date();
      setSendTime(() => date);
      const newMessage: Message = {
        content: msg,
        role: 'user',
        date: new Date().toString(),
        audio: null,
      }
      // Add the new message to the existing messages array
      setMessages([...messages, newMessage, assistantMessage]);
      setTimeFlagArr([...timeFlagArr, false]);
      let isSourceReceived = false;
      let isApiTableReceived = false;
      const controller = new AbortController();
      setIsGenerating(true);
      const location = await getLocation();
      const country = (location as any).country;
      const device = detectDevice(window.navigator);
      const browser = detectBrowser(window.navigator);
      const ip = (location as any).query;
      const os = detectOS(window.navigator)
      // this is a bit of a hack. conversations aren't set up until the user sends a message.
      // but we would like to possess a copy of the bot's cold opener. so, we're including it as some
      // kind of side channel when we send the first message.
      const coldOpening: string | undefined =
          messages.length > 1
          ? undefined
          : messages[0].content
      const fetchWithRetry = async (attempt: number = 0) => {
        const appearedOn = isInframe ? window.location.href : url
        const response =
          await fetch(
            getFullUrl('chatbot/ask-question'),
            {
              ...getRequestInit(),
              body: JSON.stringify({
                usermsg: msg,
                id: botId,
                chatlogId: logId,
                appearedOn,
                country: country ?? undefined,
                device: device ?? undefined,
                browser: browser ?? undefined,
                os: os ?? undefined,
                ip: ip ?? undefined,
                coldOpening
              }),
              signal: controller.signal,
            })
        const maxAttempts = 3
        if (response.status === 502 && attempt < maxAttempts) {
          await new Promise(resolve => setTimeout(resolve, 2000))
          return await fetchWithRetry(attempt + 1)
        }
        else {
          return response
        }
      }
      const response = await fetchWithRetry()
      if (!response.ok) {
        if (response.status === 401 || response.status === 402) {
          const result = await response.json()
          alert(result.detail);
          setMessages([
            ...messages,
            newMessage,
            {
              content: result.detail,
              role: 'assistant',
              source: null,
              audio: null,
            },
          ]);
          setIsGenerating(false);
        }
        throw new Error();
      }
      const result: {
        text: string
      } = await response.json()
      const content = result.text
      console.log(content)
      setIsGenerating(false)
      if (content.includes(sourcelink_prefix)) {
        isSourceReceived = true;
      }
      if (content.includes(table_prefix)) {
        isApiTableReceived = true;
      }
      if (!isSourceReceived && !isApiTableReceived) {
        setMessages([
          ...messages,
          newMessage,
          {
            content,
            role: 'assistant',
            date: new Date().toString(),
            audio: null,
          },
        ]);
        return
      }
      let regex = /<sourcelink>(.*?)<\/sourcelink>/;
      let match = content.match(regex);
      let source = match ? match[1] : null;
      let content_without_source = content.replace(regex, '');
      let api_table_regex = /<api-table>(.*?)<\/api-table>/;
      let api_table_match = content_without_source.match(api_table_regex);
      let table_data = api_table_match
        ? JSON.parse(api_table_match[1])
        : null;
      let content_without_table = content_without_source.replace(
        api_table_regex,
        ''
      );
      const newAnswerMessage = {
        content: content_without_table,
        role: 'assistant',
        source: source,
        tableData: table_data,
        date: new Date().toString(),
        isPlaying: true,
        isStarted: true,
      };
      setReceiveTime(date);
      setMessages([...messages, newMessage, newAnswerMessage]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setMessages, messages, botId, logId, url]
  );

  const handleInput = useCallback(
    (ev) => {
      if (
        ev.key === 'Enter' &&
        !ev.shiftKey &&
        ev.target.value !== '\n' &&
        ev.target.value
      ) {
        sendMessage(ev.target.value);
        // Clear the input field after adding the message
        ev.target.value = '';
        setText('');
        setMessageBoxHeight(30);
      }
    },
    [sendMessage]
  );

  const handleChangeText = (e) => {
    setText(e.target.value);
  };

  const handleSendIconClick = useCallback((value) => {
    sendMessage(value);
    msgRef.current.value = '';
    setText('');
    // alert("asdf");
  }, [msgRef, sendMessage]);


  useEffect(() => {
    setInitHeight(chatboxRef?.current?.scrollHeight);
  }, []);

  useEffect(() => {
    if (chatboxRef.current !== null && messagesRef.current !== null) {
      if (!isGenerating) {
        chatboxRef.current.scrollTop = height;
        setHeight(messagesRef.current.scrollHeight);
      } else {
        chatboxRef.current.scrollTop = messagesRef.current.scrollHeight;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGenerating]);

  const handleToggle = () => {
    intervalId.forEach((id) => clearInterval(id));
    setToggle(!toggle);
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage(
      {
        type: 'AITechlyChatWindowOpenState',
        isOpen: !toggle,
      },
      '*'
    );
    postWithAuthentication(
      `chatlog/add-event/${logId}`,
      {
        body: JSON.stringify({
          type: toggle ? 'close' : 'open',
        })
      },
      null,
      null,
      true);
  };

  let style: any = {
    position: 'fixed',
    cursor: 'pointer',
    background: 'white',
    borderRadius: '10px 10px 0 0',
    height: 50,
    bottom: 0,
    '&:hover': {
      boxShadow: 'rgba(100, 100, 111, 0.1) 0px 7px 29px 0px',
    },
  };

  if (positionParam === 'left') style.left = 16;
  else style.right = 16;

  if (!isInframe)
    return (
      <ChatboxCard
        props={{
          name,
          isInframe,
          handleToggle,
          avatarFilename,
          avatarName,
          appearedOn,
          chatboxRef,
          messagesRef,
          messages,
          setMessages,
          readOnly,
          isGenerating,
          botId,
          logId,
          defaultOptions,
          handleSendIconClick,
          msgRef,
          handleInput,
          text,
          handleChangeText,
          messageBoxHeight,
          setMessageBoxHeight,
          timeFlagArr,
          setTimeFlagArr,
        }}
      />
    );
  // return <>this is test demo</>;
  return (
    isShow !== 'false' && (
      <>
        <Slide
          direction="up"
          in={toggle}
          mountOnEnter
          unmountOnExit
          style={{
            width: 392,
            position: 'fixed',
            bottom: 0,
            right: 0,
          }}
        >
          <Box p={2} sx={{ borderRadius: '15px' }}>
            <ChatboxCard
              props={{
                name,
                isInframe,
                handleToggle,
                avatarFilename,
                avatarName,
                appearedOn: undefined,
                chatboxRef,
                messagesRef,
                messages,
                setMessages,
                resetChatBox,
                readOnly,
                isGenerating,
                botId,
                logId,
                defaultOptions,
                handleSendIconClick,
                msgRef,
                handleInput,
                text,
                handleChangeText,
                messageBoxHeight,
                setMessageBoxHeight,
                timeFlagArr,
                setTimeFlagArr,
              }}
            />
          </Box>
        </Slide>
        {!toggle && (
          <Box>
            <Box
              sx={style}
              onClick={() => {
                setIsHover(false);
                handleToggle();
              }}
              boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
              px={2}
              display="flex"
              justifyContent="space-between"
              width={255}
              gap={2}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 600,
                    color: 'black',
                  }}
                >
                  24/7 Chat Support
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {/* {toggle ? (
                <CloseIcon className={toggle ? "rotateLeft" : "rotateRight"} />
              ) : (
                <ChatIcon className={toggle ? "rotateLeft" : "rotateRight"} />
              )} */}

                {isHover ? (
                  <ChatBubbleOutlinedIcon
                    sx={{
                      color: 'rgb(82, 157, 252)',
                    }}
                  />
                ) : (
                  <ChatBubbleOutlineOutlinedIcon
                    sx={{
                      color: 'rgb(82, 157, 252)',
                    }}
                  />
                )}
              </Box>
            </Box>
            {/* </Zoom> */}
          </Box>
        )}
      </>
    )
  );
};

export default Chatbox;
