import React from "react";
import { v4 as uuidv4 } from "uuid";
import Chatbot from "./Chatbot";

const makeNewChatLogId = () => {
  const newLogId =uuidv4().toString()
  sessionStorage.setItem("logId", newLogId)
  return newLogId
}

const ChatbotInIframe: React.FC = () => {
  const chatLogId = sessionStorage.getItem('logId') ?? makeNewChatLogId()
  return <Chatbot chatLogId={chatLogId} />;
};
export default ChatbotInIframe;
