/* eslint-disable no-useless-escape */
interface Navigator {
  platform?: string;
  userAgent: string;
  userAgentData?: {
    platform: string;
  };
}

export const detectDevice = (navigator) => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || (window as any).opera);
  return check ? "Mobile" : "Desktop";
};

// const browserVersion = (userAgent, regex) => {
//   return userAgent.match(regex) ? userAgent.match(regex)[2] : null;
// };

export const getLocation = async () => {
  let result = await fetch(
    `https://extreme-ip-lookup.com/json/?key=${process.env.REACT_APP_IP_DETECTION_API_KEY}`
  );
  result = await result.json();
  return result;
};
export const detectBrowser = (navigator) => {
  const userAgent = navigator.userAgent;
  let browser = "unkown";
  // Detect browser name
  browser = /ucbrowser/i.test(userAgent) ? "UCBrowser" : browser;
  browser = /edg/i.test(userAgent) ? "Edge" : browser;
  browser = /googlebot/i.test(userAgent) ? "GoogleBot" : browser;
  browser = /chromium/i.test(userAgent) ? "Chromium" : browser;
  browser =
    /firefox|fxios/i.test(userAgent) && !/seamonkey/i.test(userAgent)
      ? "Firefox"
      : browser;
  browser =
    /; msie|trident/i.test(userAgent) && !/ucbrowser/i.test(userAgent)
      ? "IE"
      : browser;
  browser =
    /chrome|crios/i.test(userAgent) &&
    !/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(userAgent)
      ? "Chrome"
      : browser;
  browser =
    /safari/i.test(userAgent) &&
    !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i.test(
      userAgent
    )
      ? "Safari"
      : browser;
  browser = /opr|opera/i.test(userAgent) ? "Opera" : browser;

  return browser;

  // // detect browser version
  // switch (browser) {
  //   case "UCBrowser":
  //     return `${browser}/${browserVersion(
  //       userAgent,
  //       /(ucbrowser)\/([\d\.]+)/i
  //     )}`;
  //   case "Edge":
  //     return `${browser}/${browserVersion(
  //       userAgent,
  //       /(edge|edga|edgios|edg)\/([\d\.]+)/i
  //     )}`;
  //   case "GoogleBot":
  //     return `${browser}/${browserVersion(
  //       userAgent,
  //       /(googlebot)\/([\d\.]+)/i
  //     )}`;
  //   case "Chromium":
  //     return `${browser}/${browserVersion(
  //       userAgent,
  //       /(chromium)\/([\d\.]+)/i
  //     )}`;
  //   case "Firefox":
  //     return `${browser}/${browserVersion(
  //       userAgent,
  //       /(firefox|fxios)\/([\d\.]+)/i
  //     )}`;
  //   case "Chrome":
  //     return `${browser}/${browserVersion(
  //       userAgent,
  //       /(chrome|crios)\/([\d\.]+)/i
  //     )}`;
  //   case "Safari":
  //     return `${browser}/${browserVersion(userAgent, /(safari)\/([\d\.]+)/i)}`;
  //   case "Opera":
  //     return `${browser}/${browserVersion(
  //       userAgent,
  //       /(opera|opr)\/([\d\.]+)/i
  //     )}`;
  //   case "IE":
  //     const version = browserVersion(userAgent, /(trident)\/([\d\.]+)/i);
  //     // IE version is mapped using trident version
  //     // IE/8.0 = Trident/4.0, IE/9.0 = Trident/5.0
  //     return version
  //       ? `${browser}/${parseFloat(version) + 4.0}`
  //       : `${browser}/7.0`;
  //   default:
  //     return `unknown/0.0.0.0`;
  // }
};

export const detectOS = (navigator: Navigator) => {
  const userAgent = navigator.userAgent;
  const platform =
    navigator?.userAgentData?.platform || navigator.platform;
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];
  let os: string | null = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (/Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
};