import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TotalSessionCountCard from '../Components/Cards/TotalSessionCountCard';
import TotalMessageThisMonthCountCard from '../Components/Cards/TotalMessageThisMonthCountCard';
import TotalMessageThisWeekCountCard from '../Components/Cards/TotalMessageThisWeekCountCard';
import TotalImpressionsCard from '../Components/Cards/TotalImpressionsCard';
import AvgMessageCountCard from '../Components/Cards/AvgMessageCountCard';
import { useNavigate, useParams } from 'react-router-dom';
import SourceClickedCountCard from '../Components/Cards/SourceClickedCountCard';
import {
  Grid,
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { postWithAuthentication, getFullUrl } from '../Utils/FetchUtil';
import { BsAndroid2, BsApple, BsWindows } from 'react-icons/bs';
import { FaLinux, FaEdge, FaSafari } from 'react-icons/fa';
import { SiMacos, SiFirefox } from 'react-icons/si';
import { AiFillChrome } from 'react-icons/ai';
import { GrInternetExplorer } from 'react-icons/gr';
import DevicesIcon from '@mui/icons-material/Devices';
import TabIcon from '@mui/icons-material/Tab';
import SurroundSoundIcon from '@mui/icons-material/SurroundSound';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import DesktopMacOutlinedIcon from '@mui/icons-material/DesktopMacOutlined';
import TablePagination from '@mui/material/TablePagination';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const StyledTableCell = styled(TableCell)`
  && {
    padding: 0px 0px 0px 10px;
  }
`;

function formatDate(input) {
  let date;

  // Check if the input is a Date object
  if (input instanceof Date) {
      date = input;
  } else {
      // If not, try to convert it to a Date object
      date = new Date(input);
      // Check if the date is valid
      if (isNaN(date)) {
          throw new Error('Invalid date');
      }
  }

  const currentYear = new Date().getFullYear();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');

  let formattedDate = `${month}/${day} ${hour}:${minute}`;
  if (year !== currentYear) {
      formattedDate = `${year}/` + formattedDate;
  }

  return formattedDate;
}

enum ClickOutFilter {
  NO,
  YES,
  BOTH
}

export interface FilterOptionProp {
  startDate: string,
  endDate: string,
  slug: string,
  clickOutStatus: string
}

interface SortOption {
  name: string;
  ascending: boolean;
}

const Analytics: React.FC = () => {
  const { chatbotId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, setData] = useState(null);
  const [chatLogs, setChatLogs] = useState([]);
  const [pageCount, setPageCount] = useState<number>(1);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<Dayjs>();
  const [endDate, setEndDate] = useState<Dayjs>();
  const [slug, setSlug] = useState<string>("")
  const [clickOutStatus, setClickOutStatus] = useState<ClickOutFilter>(ClickOutFilter.BOTH)
  const [sortOption, setSortOption] = useState<SortOption>()
  const [displayData, setDisplayData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [filterOption, setFilterOption] = useState<FilterOptionProp>()

  useEffect(() => {
    setFilterRangeToDefault();
  }, []);

  const getDate = (chatLog) => {
    return new Date(chatLog.date.$date);
  }

  useEffect(() => {
    let newChatLogs = chatLogs;

    newChatLogs = newChatLogs?.filter((chatLog) => {
      return (clickOutStatus === ClickOutFilter.BOTH || 
      !!chatLog?.closures?.length === !!clickOutStatus) &&
      !!chatLog?.appearedOn?.includes(slug)
    })
    let newDisplayData = newChatLogs.map((log, ind) => ({
        No: ind + page * rowsPerPage + 1,
        Summary: log.title,
        Address: log.ip,
        "Last Message": formatDate(log.date.$date),
        Country: log.country,
        Device: log.device,
        Browser: log.browser,
        System: log.os,
        Profile: log.botName,
        Slug: log.appearedOn,
        "Click Out": log.closures,
        "Codes": log.codes,
        "Proposal Offer": log?.isBrandRecommended ? "Yes" : "No",
        "Clicked Out": log?.closures?.length ? "Yes" : "No",
        "Amount of Clickouts": log?.closures?.length,
        "Message Count": log?.msg_length,
        logId: log.logId
      }))
    if (sortOption) {
      newDisplayData.sort((log1, log2) => {
        if (log1[sortOption.name] > log2[sortOption.name])
          return 1;
        if (log1[sortOption.name] < log2[sortOption.name])
          return -1;
        return 0;
      })
      if (!sortOption.ascending) {
        newDisplayData.reverse()
      }
    }
    newDisplayData = newDisplayData.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    setDisplayData(newDisplayData)
    setIsLoading(false)
  }, [clickOutStatus, chatLogs, sortOption, page, rowsPerPage, slug]);

  useEffect(() => {
    setIsLoading(true)
    postWithAuthentication(
      'chatbot/get-all-sources',
      {
        body: JSON.stringify({
          id: chatbotId,
        }),
      },
      (result) => {
        setData(result);
      }
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const end = new Date(endDate?.format());
    end.setDate(end.getDate() + 1);
    let clickout = "Both"
    switch(clickOutStatus) {
      case ClickOutFilter.YES:
        clickout = "Yes"
        break
      case ClickOutFilter.NO:
        clickout = "No"
        break
    }
    setFilterOption({
      startDate: startDate?.format(),
      endDate: endDate ? end?.toISOString() : "",
      slug,
      clickOutStatus: clickout,
    })
    setIsLoading(true)
    postWithAuthentication(
      'chatlog/find-chatlogs',
      {
        body: JSON.stringify({
          id: chatbotId,
          startDate: startDate?.format(),
          endDate: endDate ? end?.toISOString() : "",
          slug,
          clickOutStatus: clickout,
        }),
      },
      (result) => {
        const data = JSON.parse(result);
        const newChatLogs = data["logs"]
        newChatLogs.sort((a, b) => {
          const dateA = getDate(a);
          const dateB = getDate(b);
          return dateB.getTime() - dateA.getTime();
        });
        setChatLogs(newChatLogs);
        setPageCount(data["totalLength"])
        setPage(0)
      },
      () => {
        setIsLoading(false)
      }
    );
  }, [startDate, endDate, chatbotId, slug, clickOutStatus])

  const setFilterRangeToDefault = () => {
    const date = new Date();
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    setEndDate(dayjs(newDate));
    const day = newDate.getDay();
    newDate.setDate(newDate.getDate() - day - 6);
    setStartDate(dayjs(newDate));
    setSlug("")
  };

  const redirectToChatLogs = (id) => {
    localStorage.setItem('chatlog_id', id);
    navigate(`/chatbot/${chatbotId}/chat-log`);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function getUrlWithoutQuery(urlString) {
    try {
      const urlObj = new URL(urlString);
      let urlWithoutQuery = `${urlObj.protocol}//${urlObj.hostname}`;
      if (urlObj.port) {
          urlWithoutQuery += `:${urlObj.port}`;
      }
      urlWithoutQuery += urlObj.pathname;
      return urlWithoutQuery;
    } catch (e) {
      if (urlString === 'unknown') {
        return 'unknown'
      }
      else {
        console.error(`Failed to parse url: '${urlString}'; ${e}`)
        return urlString
      }
    }
  }

  const headers = ["No", "Summary", "Address", "Last Message", "Country", "Device", "Browser", "System",
                  "Slug", "Click Out", "Codes", "Proposal Offer", "Clicked Out", "Amount of Clickouts", "Message Count"]

  const handleExport = () => {
    const data = headers.join(",") + "\n" + chatLogs.map((log, ind) => {
      console.log(JSON.stringify(log.closures))
      const array = [
        ind + page * rowsPerPage + 1,
        log.title.split('\n').join(''),
        log.ip,
        formatDate(log.date.$date),
        log.country,
        log.device,
        log.browser,
        log.os,
        log.appearedOn,
        JSON.stringify(log.closures?.map((closure) => `[${closure.name}](${closure.url})`).join(" ")),
        `"${log.codes}"`,
        log?.isBrandRecommended ? "Yes" : "No",
        log?.closures?.length ? "Yes" : "No",
        log?.closures?.length,
        log.msg_length
      ]
      return array.join(',')
    }).join('\n')

    const blob = new Blob([data], {type: "text/plain;charset=utf-8"});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'analytics.csv';
    link.click();
    setTimeout(() => {
      URL.revokeObjectURL(url) 
    }, 100) 
  }

  const handleClickOutStatus = () => {
    let newStatus: ClickOutFilter
    switch(clickOutStatus) {
      case ClickOutFilter.NO:
        newStatus = ClickOutFilter.YES
        break
      case ClickOutFilter.YES:
        newStatus = ClickOutFilter.BOTH
        break
      case ClickOutFilter.BOTH:
        newStatus = ClickOutFilter.NO
        break
    }
    setClickOutStatus(newStatus)
  }

  const handleSortClick = (header) => {
    if (sortOption && header === sortOption.name) {
      setSortOption({
        name: header,
        ascending: !sortOption.ascending
      })
    } else {
      setSortOption({
        name: header,
        ascending: true
      })
    }
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row">
        <TotalSessionCountCard botId={chatbotId} filterOption={filterOption} />
        <TotalMessageThisMonthCountCard botId={chatbotId} filterOption={filterOption} />
        <TotalMessageThisWeekCountCard botId={chatbotId} filterOption={filterOption} />
        <AvgMessageCountCard botId={chatbotId} filterOption={filterOption} />
        <SourceClickedCountCard botId={chatbotId} filterOption={filterOption} />
        <TotalImpressionsCard botId={chatbotId} filterOption={filterOption} />
      </div>
      <Box m={2} my={7}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            size="small"
            sx={{ width: '100px' }}
            variant="outlined"
            onClick={handleExport}
          >
            Export
          </Button>
        </Box>
        <Grid container >
          <Grid item xs={12} xl={7}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newVal) => setStartDate(newVal)}
                />
              </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newVal) => {
                    setEndDate(newVal)
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <TextField 
              id="slug" 
              label="Slug" 
              variant="outlined" 
              sx={{
                mt: 1
              }}
              onChange={(e) => setSlug(e.target.value)}
              value={slug} />
            <Box
              sx={{
                height: '64px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={setFilterRangeToDefault}
            >
              <RestartAltIcon fontSize="large" />
            </Box>
          </Grid>
          <Grid item xs={12} xl={5} display='flex' alignItems='center' justifyContent='space-between'>
            <Box display='flex' alignItems='center' gap={2}>
              <Typography variant='subtitle1'>Click Out</Typography>
              <Checkbox 
                checked={clickOutStatus === ClickOutFilter.YES}
                indeterminate={clickOutStatus === ClickOutFilter.BOTH}
                onChange={handleClickOutStatus} />
            </Box>
            <TablePagination
              component="div"
              count={pageCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[
                { label: '10', value: 10 },
                { label: '20', value: 20 },
                { label: '50', value: 50 },
                { label: '100', value: 100 },
              ]}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper} sx={{
          mt: 6
        }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {
                  headers.map((header, ind) => {
                    if (header === "No") {
                      return <TableCell key={header}>No</TableCell>
                    } else if (header === "Device") {
                      return <StyledTableCell key={header}>
                        <DevicesIcon />
                        </StyledTableCell>
                    } else if (header === "Browser") {
                      return <StyledTableCell key={header}><TabIcon /></StyledTableCell>
                    } else if (header === "System") {
                      return <StyledTableCell key={header}><SurroundSoundIcon /></StyledTableCell>
                    }
                    return <StyledTableCell 
                      key={header} 
                      sx={{
                        cursor: "pointer"
                      }}
                      onClick={() => handleSortClick(header)}>
                        <span style={{
                          display: "flex",
                          alignItems: "center"
                        }}>
                          <span>{header}</span>
                          {header === sortOption?.name 
                            && (sortOption.ascending 
                              ? <NorthIcon color='primary' fontSize='small' /> 
                              : <SouthIcon color='primary' fontSize='small' />)}
                        </span>
                    </StyledTableCell>
                  })
                }
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayData?.map((item) => (
                  <TableRow key={item.No}>
                    {
                      headers.map((header) => {
                        const data = item[header]
                        if (header === "No") {
                          return <TableCell key={header}>{data}</TableCell>
                        } else if (header === "Device") {
                          return <StyledTableCell
                            key={header}
                          >
                            {data === 'Desktop' ? (
                              <DesktopMacOutlinedIcon />
                            ) : (
                              <PhoneAndroidIcon />
                            )}
                          </StyledTableCell>
                        } else if (header === "Browser") {
                          return <StyledTableCell
                            key={header}
                          >
                            {data === 'Edge' ? (
                              <FaEdge style={{ fontSize: '20px' }} />
                            ) : data === 'Firefox' ? (
                              <SiFirefox style={{ fontSize: '20px' }} />
                            ) : data === 'Safari' ? (
                              <FaSafari style={{ fontSize: '20px' }} />
                            ) : data === 'Chrome' ? (
                              <AiFillChrome style={{ fontSize: '20px' }} />
                            ) : data === 'IE' ? (
                              <GrInternetExplorer style={{ fontSize: '20px' }} />
                            ) : null}
                          </StyledTableCell>
                        } else if (header === "System") {
                          return <StyledTableCell
                            key={header}
                            sx={{ padding: ['0px', '0px', '0px', '10px'] }}
                          >
                            {data === 'Windows' ? (
                              <BsWindows style={{ fontSize: '20px' }} />
                            ) : data === 'Linux' ? (
                              <FaLinux style={{ fontSize: '20px' }} />
                            ) : data === 'Android' ? (
                              <BsAndroid2 style={{ fontSize: '20px' }} />
                            ) : data === 'iOS' ? (
                              <BsApple style={{ fontSize: '20px' }} />
                            ) : data === 'Mac OS' ? (
                              <SiMacos style={{ fontSize: '20px' }} />
                            ) : null}
                          </StyledTableCell>
                        } else if (header === "Slug") {
                          return <StyledTableCell
                            key={header}
                          >
                            <a href={getUrlWithoutQuery(data)} target="_blank" rel="noreferrer">{data}</a>
                          </StyledTableCell>
                        } else if (header === "Click Out") {
                          return <StyledTableCell
                            key={header}
                          >
                            {
                              data?.map((closure, ind) => {
                                return <a key={`closure-url-${ind}`} href={closure.url} target='_blank' rel='noreferrer'>
                                    {closure.name}
                                    <br/>
                                  </a>
                              })
                            }
                          </StyledTableCell>
                        } else if (header === "Codes") {
                          return <StyledTableCell
                            key={header}
                          >
                            {data.length > 1
                              ? <Tooltip title={data.join(",")}>
                                  <span>{data[0]}, ...</span>
                                </Tooltip>
                              : data.join(",")}
                          </StyledTableCell>
                        }
                        return <StyledTableCell
                            key={header}
                          >{data}</StyledTableCell>
                      })
                    }
                    <TableCell>
                      <Button
                        size="small"
                        sx={{ width: '100px' }}
                        variant="outlined"
                        onClick={() => redirectToChatLogs(item.logId)}
                      >
                        Chat log
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default Analytics;
