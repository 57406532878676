import React, { useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  TextareaAutosize,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { Typing } from './Typing';
import Markdown from 'react-markdown';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { TableInChatbox } from './Tables/TableInChatbox';
import { Link } from 'react-router-dom';
import { postWithAuthentication } from '../Utils/FetchUtil';
import { useSelector } from 'react-redux';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

function removeMarkdownLink(text: string) {
  return text.replace(/\[(.*?)\]\(.*?\)/g, '$1');
}

const ChatboxCard = ({ props }) => {
  const {
    name,
    isInframe,
    handleToggle,
    avatarName,
    avatarFilename,
    appearedOn,
    chatboxRef,
    messagesRef,
    messages,
    setMessages,
    resetChatBox,
    readOnly,
    isGenerating,
    logId,
    defaultOptions,
    handleSendIconClick,
    msgRef,
    handleInput,
    text,
    handleChangeText,
    messageBoxHeight,
    setMessageBoxHeight,
    timeFlagArr,
    setTimeFlagArr,
  } = props;
  const [expanded, setExpanded] = useState(true);
  const chatbot = useSelector((state: any) => state.chatbot);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    resetChatBox();
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    if (event.target.value !== '\n') {
      handleChangeText(event);
      const currentText = event.target.value;
      const lineCount = (currentText.match(/\n/g) || []).length;
      setMessageBoxHeight((lineCount > 3 ? 3 : lineCount) * 25 + 30);
    }
  };

  const handleTimeFlagArr = (index) => {
    setTimeFlagArr([
      ...timeFlagArr.slice(0, index),
      !timeFlagArr[index],
      ...timeFlagArr.slice(index + 1),
    ]);
  };

  function cleanMarkdown(document) {
    if (!document) return '';

    document = removeEmptyMarkdowns(document);

    function removeEmptyMarkdowns(text) {
      const pattern: RegExp = /\[\]\([^)]+\)/g;
      const cleanedText: string = text.replace(pattern, '');
      return cleanedText
    }

    function isBlank(line) {
      return line.trim() === '';
    }

    function isListItem(line) {
      if (!line) {
        return false;
      }

      // Regular expression to match bullet points (starting with -, *, or +) or numbered list items
      // eslint-disable-next-line no-useless-escape
      const listItemRegex = /^(\s*(\*|\-|\+|\d+\.)\s).+$/;

      return listItemRegex.test(line.trim());
    }

    function canDeleteBasedOnContext(previousLine, nextLine) {
      return (
        previousLine === undefined ||
        isBlank(previousLine) ||
        (isListItem(previousLine) && isListItem(nextLine))
      );
    }

    const lines = document.split('\n');

    function allowLine(line, index) {
      // Retain the line if it's not empty
      if (!isBlank(line)) return true;

      // Apply the rule to determine if the current line can be deleted
      return (
        canDeleteBasedOnContext(lines[index - 1], lines[index + 1]) === false
      );
    }

    return lines.filter(allowLine).join('\n');
  }

  const renderLink = (props) => {
    const handleLinkClicked = () => {
      postWithAuthentication(
        `chatlog/${logId}/closure`,
        {
          body: JSON.stringify({
            url: props.href,
          }),
        },
        (result) => {}
      );
    };
    return (
      <a
        href={props.href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleLinkClicked}
      >
        {props.children}
      </a>
    );
  };

  const getTime = (dateString) => {
    let date: Date;
    if (dateString) {
      date = new Date(dateString);
    } else {
      date = new Date();
    }
    const hours = date?.getUTCHours()
    const minutes = date?.getUTCMinutes();
    let time;
    if (hours >= 12) {
      time = `${
        hours === 12 ? 12 : (hours - 12).toString().padStart(2, '0')
      }:${minutes.toString().padStart(2, '0')} PM`;
    } else {
      time = `${hours === 0 ? 12 : hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')} AM`;
    }
    return time;
  };

  const getText = (type) => {
    switch (type) {
      case 'open':
        return 'Opened';
      case 'close':
        return 'Closed';
      case 'automaticOpen':
        return 'Automatically Opened';
      default:
        return '';
    }
  };

  const handleMessages = (index, newValue) => {
    setMessages([
      ...messages.slice(0, index),
      {
        ...messages[index],
        ...newValue,
      },
      ...messages.slice(index + 1),
    ]);
  };

  return (
    <Card
      id="chat1"
      style={{
        borderRadius: '15px',
        overflowY: 'auto',
        width: '100%',
        // height: "80vh",
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
      }}
    >
      <CardHeader
        title={
          <Box height={expanded ? 204 : 56}>
            <Box
              position="relative"
              display="flex"
              alignItems="center"
              justifyContent="center"
              zIndex={10}
              height={56}
              padding={'10px 0px'}
              sx={{
                backgroundColor: 'white',
              }}
            >
              {isInframe && (
                <>
                  <Box
                    position="absolute"
                    sx={{
                      bottom: 12,
                      left: 20,
                      width: 32,
                      height: 32,
                      cursor: 'pointer',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      opacity: 0.7,
                      ':hover': {
                        backgroundColor: '#eee',
                      },
                    }}
                    onClick={handleClick}
                  >
                    <MoreHorizIcon />
                  </Box>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={handleReset}
                    >
                      <RestartAltIcon />
                      <span
                        style={{
                          marginLeft: 5,
                        }}
                      >
                        Reset
                      </span>
                    </MenuItem>
                  </Menu>
                </>
              )}
              {!expanded && (
                <Box
                  className="chatbox-header"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  px={1}
                  py={0.5}
                  borderRadius={1.5}
                  onClick={() => setExpanded(true)}
                >
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    variant="dot"
                  >
                    <Avatar
                      alt="Profile image"
                      src={`/images/${avatarFilename}.webp`}
                      sx={{ width: 30, height: 30 }}
                      variant="circular"
                    />
                  </StyledBadge>
                  <Typography
                    textAlign="center"
                    fontSize="14px"
                    fontWeight={600}
                    color="rgba(0, 0, 0, 0.8)"
                    ml="4px !important"
                  >
                    {name}
                  </Typography>
                </Box>
              )}
              {isInframe && (
                <Box
                  position="absolute"
                  sx={{
                    bottom: 12,
                    right: 20,
                    width: 32,
                    height: 32,
                    cursor: 'pointer',
                    borderRadius: '6px',
                    opacity: 0.7,
                    ':hover': {
                      backgroundColor: '#eee',
                    },
                  }}
                  onClick={handleToggle}
                >
                  <svg color="inherit" viewBox="0 0 32 32" aria-hidden="true">
                    <path d="M24.1818182,21 C24.6336875,21 25,21.4477153 25,22 C25,22.5522847 24.6336875,23 24.1818182,23 L7.81818182,23 C7.36631248,23 7,22.5522847 7,22 C7,21.4477153 7.36631248,21 7.81818182,21 L24.1818182,21 Z"></path>
                  </svg>
                </Box>
              )}
            </Box>
            <Box
              className="expanded-header"
              display="flex"
              flexDirection="column"
              alignItems="center"
              pt={0}
              pb={4}
              px={3}
              sx={{
                transform: `translateY(${expanded ? 0 : -148}px)`,
                backgroundColor: 'white',
                boxShadow:
                  'rgba(0, 0, 0, 0.05) 0px 0.48px 2.41px -0.38px, rgba(0, 0, 0, 0.17) 0px 4px 20px -0.75px',
              }}
              position="relative"
              zIndex={9}
            >
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                variant="dot"
              >
                <Avatar
                  alt="Profile image"
                  src={`/images/${avatarFilename}.webp`}
                  sx={{ width: 60, height: 60 }}
                  variant="circular"
                />
              </StyledBadge>
              <Typography
                sx={{
                  textAlign: 'left',
                  fontWeight: 600,
                }}
                mt="8px !important"
              >
                AI Assistant
              </Typography>
              <Typography
                sx={{
                  textAlign: 'left',
                  fontWeight: 300,
                  color: 'gray',
                }}
              >
                {avatarName === '' ? 'Hosting Expert' : avatarName}
              </Typography>
            </Box>
          </Box>
        }
        style={{
          backgroundColor: 'white',
          zIndex: 10,
          position: 'relative',
          padding: 0,
        }}
      />

      <CardContent
        sx={{
          bgcolor: 'rgb(237, 237, 237)',
          // transform: `translateY(${expanded ? 0 : -148}px)`
        }}
        onClick={() => setExpanded(false)}
      >
        <div
          className="chatbox"
          style={{
            height: `calc(min(750px, 100vh) - 173px - ${
              (messageBoxHeight === 30 ? 39 : messageBoxHeight) +
              (expanded ? 148 : 0)
            }px)`,
          }}
          ref={chatboxRef}
        >
          <div ref={messagesRef}>
            {!isInframe && appearedOn && (
              <div className="d-flex justify-content-center">
                Chat opened at&nbsp;
                <span style={{ color: 'gray', fontWeight: 'normal' }}>
                  {appearedOn}
                </span>
              </div>
            )}
            {messages.map((message, index) =>
              message.type ? (
                <div
                  key={`message-${index}`}
                  className="d-flex justify-content-center"
                >
                  {getTime(message.date)} {getText(message.type)}
                </div>
              ) : message.role === 'assistant' ? (
                <div key={`message-${index}`}>
                  <div
                    className="d-flex flex-row justify-content-start mb-3"
                    onClick={() => handleTimeFlagArr(index)}
                  >
                    {message.content === '...' ? (
                      <>
                        <Typing avatarFilename={avatarFilename} loadingBar={chatbot.errorCode === 502} />
                      </>
                    ) : (
                      <>
                        <label htmlFor="avatar">
                          <Avatar
                            alt="Avatar"
                            src={`/images/${avatarFilename}.webp`}
                            sx={{ width: 24, height: 24, cursor: 'pointer' }}
                          />
                        </label>
                        <div className="d-flex flex-column ms-2 align-items-start">
                          <div
                            className={`message-date ${
                              !timeFlagArr[index] ? 'hide' : ''
                            }`}
                          >
                            {name} {getTime(message.date)}
                          </div>
                          <div
                            className="px-3 chat-msg"
                            style={{
                              borderRadius: '10px',
                              backgroundColor: '#FFF',
                              paddingTop: '0.75rem',
                              paddingBottom: '0.75rem',
                            }}
                          >
                            <div
                              className="small mb-0"
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 8,
                                textAlign: 'left',
                              }}
                            >
                              <Markdown
                                components={{
                                  a: renderLink,
                                }}
                              >
                                {cleanMarkdown(message.content)}
                              </Markdown>
                              {readOnly && message.feedback === 1 && (
                                <FontAwesomeIcon
                                  icon={faThumbsUp}
                                  className="mx-1 text-right text-red"
                                />
                              )}
                              {readOnly && message.feedback === -1 && (
                                <FontAwesomeIcon
                                  icon={faThumbsDown}
                                  className="mx-1 text-right text-red"
                                />
                              )}
                              {message.tableData &&
                                message.tableData.length > 0 && (
                                  <TableInChatbox data={message.tableData} />
                                )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {messages.length === 1 && (
                    <Box display="flex" gap={1} ml={6} flexWrap="wrap">
                      {defaultOptions.map((item, i) => {
                        return (
                          <Chip
                            key={`option-${i}`}
                            label={removeMarkdownLink(item)}
                            color="primary"
                            variant="outlined"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                              msgRef.current.value = item;
                              handleSendIconClick(item);
                            }}
                          />
                        );
                      })}
                    </Box>
                  )}
                </div>
              ) : (
                <div
                  key={`message-${index}`}
                  className="d-flex flex-row justify-content-end mb-3"
                  onClick={() => handleTimeFlagArr(index)}
                >
                  <div className="d-flex flex-column align-items-end me-2">
                    <div
                      className={`message-date ${
                        !timeFlagArr[index] ? 'hide' : ''
                      }`}
                    >
                      Visitor {getTime(message.date)}
                    </div>
                    <div
                      className="px-3 border"
                      style={{
                        borderRadius: '10px',
                        backgroundColor: 'rgb(26, 148, 218)',
                        paddingTop: '0.75rem',
                        paddingBottom: '0.75rem',
                      }}
                    >
                      <p
                        className="small mb-0"
                        style={{
                          whiteSpace: 'pre-wrap',
                          textAlign: 'left',
                          color: 'white',
                        }}
                      >
                        <Markdown>
                          {cleanMarkdown(message.content)}
                        </Markdown>
                      </p>
                    </div>
                  </div>
                  <Avatar
                    alt="Avatar"
                    sx={{ width: 24, height: 24, cursor: 'pointer' }}
                  />
                </div>
              )
            )}
          </div>
        </div>
      </CardContent>
      <CardActions
        sx={{
          padding: '12px 20px',
          // transform: `translateY(${expanded ? 0 : -148}px)`
        }}
      >
        <TextareaAutosize
          className="focus-no-border"
          placeholder="Write a message..."
          // type="text"
          ref={msgRef}
          onKeyUp={handleInput}
          disabled={isGenerating}
          // variant="standard"
          style={{
            fontSize: 14,
            border: 'none',
            width: '100%',
            resize: 'none',
            maxHeight: '110px',
            backgroundColor: 'white',
          }}
          value={text}
          onChange={handleChange}
        />
        <Box
          display="flex"
          alignItems="center"
          gap={0.5}
          sx={{
            cursor: 'pointer',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            bgcolor={text ? 'rgb(26, 148, 218)' : 'transparent'}
            borderRadius={1.5}
            padding={0.25}
          >
            <Link onClick={text ? () => handleSendIconClick(text) : null} to="">
              <SendIcon
                fontSize="large"
                sx={{
                  width: 28,
                  height: 28,
                  color: text ? '#FFF' : 'rgba(0, 0, 0, 0.3)',
                  cursor: text ? 'pointer' : 'not-allowed',
                }}
              />
            </Link>
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};

export default ChatboxCard;
