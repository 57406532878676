import React,  {useEffect, useState} from 'react'
import Box from "@mui/material/Box";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import dayjs, { Dayjs } from 'dayjs';
import { postWithAuthentication } from '../Utils/FetchUtil';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { codeDetails } from '../Constants/codesManifest';

const Tests = () => {
  const { chatbotId } = useParams();
  const headers = ["No", "Code", "Description", "First Use Date", "Last Use Date", "Affected Log Count", "Clicked Log Count", "Total Count", "Click Out Rate"]
  const [startDate, setStartDate] = useState<Dayjs>(dayjs(""));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs(""));
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setFilterRangeToDefault()
  }, [])

  useEffect(() => {
    if (endDate.isValid()) {
      const end = new Date(endDate?.format());
      end.setDate(end.getDate() + 1);
      setIsLoading(true)
      postWithAuthentication(
        'chatlog/get-tests-data',
        {
          body: JSON.stringify({
            id: chatbotId,
            startDate: startDate?.format(),
            endDate: endDate ? end?.toISOString() : "",
          }),
        },
        (result) => {
          const parsedResult = JSON.parse(result);
          setData(parsedResult.map((row, index) => {
            const codeIndex = codeDetails.findIndex((item) => item["code"] === row["code"])
            return {
              No: index + 1,
              Code: row["code"],
              Description: row["description"] ? row["description"] : codeIndex === -1 ? "Not Provided" : codeDetails[codeIndex]["description"],
              "First Use Date": moment(new Date(row["first"].$date)).utc().format('MM/DD/YYYY HH:mm'),
              "Last Use Date": moment(new Date(row["last"].$date)).utc().format('MM/DD/YYYY HH:mm'),
              "Affected Log Count": row["log_count"],
              "Clicked Log Count": row["clicked_log_count"],
              "Total Count": row["msg_count"],
              "Click Out Rate": (row["clicked_log_count"] * 100 / row["log_count"]).toFixed(2) + "%"
            }
          }))
          setIsLoading(false)
        }
      );
    }
  }, [startDate, endDate, chatbotId])

  const setFilterRangeToDefault = () => {
    const date = new Date();
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    setEndDate(dayjs(newDate));
    newDate.setMonth(newDate.getMonth() - 1);
    setStartDate(dayjs(newDate));
  };

  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container >
        <Grid item xs={12} xl={7}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newVal) => setStartDate(newVal)}
              />
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newVal) => {
                  setEndDate(newVal)
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
          <Box
            sx={{
              height: '64px',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={setFilterRangeToDefault}
          >
            <RestartAltIcon fontSize="large" />
          </Box>
        </Grid>
      </Grid>
      <TableContainer component={Paper} sx={{
        mt: 6
      }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {
                headers.map((header) => <TableCell key={header}>{header}</TableCell>)
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data.map((row) => <TableRow key={row["Code"]}>
                {headers.map((header) => <TableCell key={header} sx={{maxWidth: 200, overflowWrap: "break-word"}}>{row[header]}</TableCell>)}
              </TableRow>)
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>)
}
export default Tests