import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../Styles/chatbot.css';
import { postWithAuthentication, getFullUrl, getRequestInit } from '../Utils/FetchUtil';
import Chatbox from '../Components/Chatbox';
import { Box, TextField } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useLocation } from 'react-router-dom';

interface ChatbotProps {
  chatLogId: string;
}

interface BotProps {
  name: string;
  active: boolean;
  defaultGreetingMessage?: string;
  defaultOptions?: Array<string>;
  pages?: Array<any>;
  avatarName?: string;
  avatarImageId?: string;
}

interface Message {
  content: string
  role: string
  date: string
}

const Chatbot: React.FC<ChatbotProps> = ({ chatLogId }) => {
  const isInframe = window.self !== window.top
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const defaultUrl: string =
    isInframe
    ? searchParams.get('parentUrl')
    : window.location.href
  const { chatbotId } = useParams()
  const [bot, setBot] = useState<BotProps | null>(null)
  const [url, setUrl] = useState<string>(defaultUrl)
  const [messages, setMessages] = useState<Message[]>([])

  useEffect(() => {
    if (bot && messages.length < 2) {
      (async () => {
        const response = await fetch(
          getFullUrl('chatlog/find_messages_by_id'),
          {
            ...getRequestInit(),
            body: JSON.stringify({
              logId: chatLogId,
            })
          })
          if (response.ok) {
            const result = await response.json()
            const messages: Message[] =
              result.messages.map(
                (x) => ({...x, "date": x?.date.$date}))
            setMessages(messages)
            setTimeFlagArr([...messages.map(() => false)])
          }
          else if (response.status === 404)
          {
            setMessages(
              [{
                content: bot.defaultGreetingMessage,
                role: 'assistant',
                date: new Date().toISOString(),
              }])
            setTimeFlagArr([false])
          }
          else {
            console.error(`${response.url}; ${response.status}; ${response.statusText}`)
          }
      })()
    }
  }, [ chatLogId, bot, messages.length ])

  const [timeFlagArr, setTimeFlagArr] = useState([false]);

  useEffect(() => {
    postWithAuthentication(
      'chatbot/find-chatbot-by-id',
      {
        body: JSON.stringify({
          id: chatbotId,
          url
        }),
      },
      (result) => {
        setBot(result)
      }
    )
  },
  [chatbotId, url]);

  const handleReset = () => {
    setBot(null)
  }

  if (isInframe) {
    return (
      bot?.active && (
        <Chatbox
          name={bot?.name}
          botId={chatbotId}
          messages={messages}
          defaultOptions={bot?.defaultOptions}
          setMessages={setMessages}
          logId={chatLogId}
          chatbot={bot}
          timeFlagArr={timeFlagArr}
          setTimeFlagArr={setTimeFlagArr}
          url={url}
        />
      )
    );
  }
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={1} gap={1}>
        <TextField value={url} sx={{width: "90%"}} onChange={(e) => setUrl(e.target.value)}/>
        <Box
              sx={{
                height: '64px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={handleReset}
            >
            <RestartAltIcon fontSize="large" />
          </Box>
      </Box>
      <Box
        width="100%"
        gridTemplateColumns={['1fr', '1fr', '1fr 2.5fr']}
        gap={2}
      >
        <Chatbox
          name={bot?.name}
          botId={chatbotId}
          messages={messages}
          defaultOptions={bot?.defaultOptions}
          setMessages={setMessages}
          logId={chatLogId}
          chatbot={bot}
          avatarName={bot?.avatarName}
          avatarImageId={bot?.avatarImageId}
          timeFlagArr={timeFlagArr}
          setTimeFlagArr={setTimeFlagArr}
          url={url}
        />
      </Box>
    </Box>
  );
};

export default Chatbot;
