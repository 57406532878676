import {
  Avatar,
  Box,
  Button,
  Chip,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  postWithAuthentication,
  SendUploadRequestWithToken_test,
} from '../Utils/FetchUtil';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateChatbot } from '../Slice/chatbotSlice';
import { ColorPickerContainer } from '../Components/ColorPickerContainer';
import { Dropdown } from 'react-bootstrap';

const ColorPicker = (props) => {
  return (
    <ColorPickerContainer>
      <input type="color" {...props} />
      <Typography>{props.value}</Typography>
    </ColorPickerContainer>
  );
};

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

interface DataProps {
  id: string;
  name: string;
  avatarName: string;
  color: string;
  avatarImageId: string;
  summaryType?: number;
  responderKey: string | null;
}

interface Responder {
  key: string
  displayName: string
}

const responders: Responder[] = [
  {
    key: "echo",
    displayName: "Echo"
  },
  {
    key: "hostadvice",
    displayName: "Host Advice"
  },
  {
    key: "topoffer",
    displayName: "Top Offer"
  },
  {
    key: "shadowban",
    displayName: "Shadow ban"
  }
]

export const Settings = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [avatar, setAvatar] = useState(null);
  const { chatbotId } = useParams();
  const dispatch = useDispatch();

  const [data, setData] = useState<DataProps>({
    id: chatbotId,
    name: '',
    avatarName: '',
    color: '#54b4d3',
    avatarImageId: '',
    responderKey: null
  });

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    if (chatbotId) {
      postWithAuthentication(
        'chatbot/find-chatbot-by-id',
        {
          body: JSON.stringify({
            id: chatbotId,
          }),
        },
        (result) => {
          setData({
            ...data,
            ...result,
          });
        }
      );
    }
  };

  const handleDataChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    let key = name
    if (name === 'summaryType') {
      value = parseInt(value)
    }
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleChangeColor = (e) => {
    setData({
      ...data,
      color: e.target.value,
    });
  };

  const submit = async () => {
    uploadSettings(data);
  };

  const uploadAvatar = (avatarFile) => {
    const formData = new FormData();
    formData.append('avatar', avatarFile);
    SendUploadRequestWithToken_test(
      'chatbot/upload-avatar',
      {
        body: formData,
      },
      (result) => {
        setData({
          ...data,
          avatarImageId: result,
        });
        // uploadSettings(data);
      }
    );
  };

  const uploadSettings = (settingsData) => {
    postWithAuthentication(
      'chatbot/update-setting',
      {
        body: JSON.stringify({
          ...settingsData,
        }),
      },
      (result) => {
        if (result) {
          alert('Settings updated successfully');
          dispatch(
            updateChatbot({
              id: chatbotId,
              data: {
                ...settingsData,
              },
            })
          );
        } else {
          alert('Something went wrong!');
        }
      }
    );
  };

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box
        display="flex"
        gap={6}
        boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
        px={4}
        py={3}
        borderRadius="10px"
      >
        <Box display="flex" flexDirection="column" sx={{ cursor: 'pointer' }}>
          <label htmlFor="avatar">
            <Avatar
              alt="Avatar"
              src={`${process.env.REACT_APP_API_HOST}static/${data.avatarImageId}`}
              sx={{ width: 80, height: 80, cursor: 'pointer' }}
            />
          </label>
          <label htmlFor="avatar" style={{ cursor: 'pointer' }}>
            <Typography mt={1} color="rgba(0, 0, 0, 0.6)">
              Edit Avatar
            </Typography>
          </label>
          <TextField
            type="file"
            id="avatar"
            sx={{ display: 'none' }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAvatar(e.target.files && e.target.files[0]);
              uploadAvatar(e.target.files && e.target.files[0]);
            }}
          />

          {avatar && (
            <Typography color="rgba(0, 0, 0, 0.4)">
              {avatar.name.slice(0, 10)}
            </Typography>
          )}
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          gap={1}
          justifyContent="center"
        >
          <Box display="flex" gap={1}>
            <Typography>Name of Chatbot</Typography>
            <Typography color="red">*</Typography>
          </Box>
          <Box>
            <TextField
              placeholder="Test1"
              variant="outlined"
              size="small"
              sx={{ width: '100%' }}
              value={data.name}
              name="name"
              onChange={handleDataChange}
            />
          </Box>
          <Box display="flex" gap={1}>
            <Typography>Avatar Name</Typography>
            <Typography color="red">*</Typography>
          </Box>
          <Box>
            <TextField
              placeholder="Avatar Name"
              variant="outlined"
              size="small"
              sx={{ width: '100%' }}
              value={data.avatarName}
              name="avatarName"
              onChange={handleDataChange}
            />
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Box>
              <Typography align="left">Chatbot colour: </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <ColorPicker onChange={handleChangeColor} value={data.color} />
            </Box>
          </Box>
          <Box display="flex" gap={1}>
            <Typography>Responder</Typography>
          </Box>
          <Box>
            <Dropdown>
              <Dropdown.Toggle>
                {data.responderKey ? responders.find(responder => responder.key === data.responderKey).displayName : "Select Responder"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {responders.map(responder => (
                  <Dropdown.Item
                    key={responder.key}
                    onClick={() => {
                      setData({
                        ...data,
                        responderKey: responder.key
                      })
                    }}>
                    {responder.displayName}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="end" gap={4}>
        <Box>
          <Button
            size="large"
            sx={{ color: 'black', borderRadius: '7px', fontWeight: 600 }}
          >
            Close
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            sx={{ borderRadius: '7px', fontWeight: 600 }}
            size="large"
            onClick={submit}
          >
            Update
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

