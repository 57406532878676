import { styled } from 'styled-components';

export const ColorPickerContainer = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  width: 150px;
  max-width: 150px;
  border: 1px solid #bfc9d9;
  border-radius: 4px;

  input[type='color'] {
    margin-right: 8px;
    -webkit-appearance: none;
    border: none;
    width: auto;
    height: auto;
    cursor: pointer;
    background: none;
    &::-webkit-color-swatch-wrapper {
      padding: 0;
      width: 25px;
      height: 25px;
    }
    &::-webkit-color-swatch {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 0;
    }
  }
`;
