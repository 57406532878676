import React, { useCallback, useEffect, useRef, useState } from 'react';
import Chatbox from '../Components/Chatbox';
import { postWithAuthentication, getFullUrl, getRequestInit } from '../Utils/FetchUtil';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Box } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ReportIcon from '@mui/icons-material/Report';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const ChatLogList: React.FC = () => {
  const { chatbotId, chatLogId } = useParams();
  const navigate = useNavigate()
  const [messages, setMessages] = useState<Array<any>>([]);
  const [chatLogs, setChatLogs] = useState<Array<any>>([]);
  const [filteredChatLogs, setFilteredChatLogs] = useState<Array<any>>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [chatbot, setChatbot] = useState({});
  const [startDate, setStartDate] = useState<Dayjs>();
  const [endDate, setEndDate] = useState<Dayjs>();
  const [isEvaluated, setIsEvaluated] = useState<boolean>(false);
  const [hasShadowban, setHasShadowban] = useState<boolean>(false)
  const [timeFlagArr, setTimeFlagArr] = useState([false]);
  const [isLoading, setIsLoading] = useState(false)
  const logListRef = useRef(null)

  useEffect(() => {
    setFilterRangeToDefault();
  }, []);

  useEffect(() => {
    const index = filteredChatLogs.findIndex(chatlog => chatlog.logId === chatLogId)
    if (index !== -1)
      handleSelect(index)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatLogId, filteredChatLogs])

  useEffect(() => {
    let newChatLogs = chatLogs;
    newChatLogs.sort((log1, log2) => {
      const date1 = new Date(
        log1.date.$date
      );
      const date2 = new Date(
        log2.date.$date
      );
      if (date1 < date2) {
        return 1;
      } else if (date1 > date2) {
        return -1;
      }
      return 0;
    });
    setFilteredChatLogs(newChatLogs);
    setIsLoading(false)
  }, [chatLogs]);

  useEffect(() => {
    setIsLoading(true)
    const start = new Date(startDate?.format())
    const end = new Date(endDate?.format());

    //Set start date as the starting datetime(00:00:00) in UTC
    start.setUTCFullYear(start.getFullYear())
    start.setUTCMonth(start.getMonth())
    start.setUTCDate(start.getDate())
    start.setUTCHours(0)
    start.setUTCMinutes(0)
    start.setUTCSeconds(0)

    //Set end date as the ending datetime(23:59:59) in UTC
    end.setUTCFullYear(end.getFullYear())
    end.setUTCMonth(end.getMonth())
    end.setUTCDate(end.getDate())
    end.setUTCHours(23)
    end.setUTCMinutes(59)
    end.setUTCSeconds(59)
    postWithAuthentication(
      'chatlog/find-chatlogs',
      {
        body: JSON.stringify({
          id: chatbotId,
          startDate: startDate?.format(),
          endDate: endDate ? end?.toISOString() : "",
        }),
      },
      (result) => {
        const data = JSON.parse(result);
        const newChatLogs = data["logs"]
        setChatLogs(newChatLogs);
      },
      () => {
        setIsLoading(false)
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatbotId, startDate, endDate]);

  useEffect(() => {
    postWithAuthentication(
      'chatbot/find-chatbot-by-id',
      {
        body: JSON.stringify({
          id: chatbotId,
        }),
      },
      (result) => {
        setChatbot(result);
      }
    );
  }, [chatbotId]);

  const handleShadowbanToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasShadowban(event.target.checked)
    postWithAuthentication(
      `chatlog/set-has-shadowban/${chatLogId}`,
      {
        body: JSON.stringify({
          hasShadowban: event.target.checked,
        }),
      },
      (result) => {}
    );
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEvaluated(event.target.checked);
    setChatLogs([
      ...filteredChatLogs.slice(0, selectedIndex),
      {
        ...filteredChatLogs[selectedIndex],
        is_evaluated: event.target.checked,
      },
      ...filteredChatLogs.slice(selectedIndex + 1),
    ]);
    postWithAuthentication(
      `chatlog/set-is-evaluated/${chatLogId}`,
      {
        body: JSON.stringify({
          isEvaluated: event.target.checked,
        }),
      },
      (result) => {}
    );
  };

  const setFilterRangeToDefault = () => {
    const date = new Date();
    setEndDate(dayjs(date));
    const day = date.getDay();
    date.setDate(date.getDate() - day - 6);
    setStartDate(dayjs(date));
  };

  const sortItems = (messages, events) => {
    const items = [...messages, ...events];
    items.sort((item1, item2) => {
      const date1 = new Date(item1.date);
      const date2 = new Date(item2.date);
      if (date1 < date2) {
        return -1;
      } else if (date1 > date2) {
        return 1;
      }
      return 0;
    });
    return items;
  };

  const fetchMessages = useCallback((logId) => {
    (async () => {
      const response = await fetch(
        getFullUrl('chatlog/find_messages_by_id'),
        {
          ...getRequestInit(),
          body: JSON.stringify({
            logId: logId,
          })
        })
      const result: {
        messages: Array<any>,
        events: Array<any>
      } = await response.json()
      const messages =
        result.messages.map(
          (message) => ({
            ...message,
            "date": new Date(message.date).toString()
          }))
      const events = result.events.map((event) => ({...event, "date": event?.date.$date}))
      setMessages([
        ...sortItems(messages, events),
      ])
      setTimeFlagArr([false, ...messages.map(() => false)]);
    })()
  }, []);
  
  const handleSelectItem = useCallback((index) => {
    navigate(`/chatbot/${chatbotId}/chat-log/${filteredChatLogs[index].logId}`);
  }, [chatbotId, filteredChatLogs, navigate])

  const handleSelect = useCallback(
    (index) => {
      if (logListRef.current.scrollTop === 0 && index >= 9) {
        logListRef.current.scrollTop = 74 * index
      }
      setSelectedIndex(index);
      setIsEvaluated(!!filteredChatLogs[index].is_evaluated)
      setHasShadowban(!!filteredChatLogs[index].hasShadowban)
      fetchMessages(filteredChatLogs[index].logId);
    },
    [filteredChatLogs, fetchMessages]
  );

  const getFullDateTimeString = (date: Date) => {
    return moment(date).utc().format('MM/DD/YYYY HH:mm')
  }

  return (
    <div className="row py-3">
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
      <div className="col-3">
        <h1>Chat Logs</h1>
        <hr />
        <div
          ref={logListRef}
          className="list-group list-group-light"
          id="list-tab"
          role="tablist"
          style={{
            height: '650px',
            overflowY: 'auto',
          }}
        >
          {filteredChatLogs.length === 0 && <h2>No Chat Logs</h2>}
          {!!filteredChatLogs.length &&
            filteredChatLogs.map((chatLog, index) => (
              <div
                key={`date-list-${index}`}
                className={`list-group-item list-group-item-action px-3 border-0 d-flex justify-content-center ${
                  (chatLogId && chatLogId === chatLog.logId && 'active') ||
                  (selectedIndex === index && 'active')
                }`}
                style={{
                  height: 74
                }}
                onClick={() => handleSelectItem(index)}
              >
                <span
                  id="list-home-list"
                  data-mdb-toggle="list"
                  role="tab"
                  aria-controls="list-home"
                  style={{
                    textAlign: 'left',
                    color: chatLog.is_evaluated ? 'gray' : 'black',
                  }}
                >
                  {chatLog.title
                    ? Array.isArray(chatLog.title)
                      ? chatLog.title.join('').replace(/"/g, '')
                      : chatLog.title.replace(/"/g, '')
                    : 'New ChatLog'}
                </span>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    {!!chatLog?.closures?.length && <InsertLinkIcon color="primary" />}
                    {chatLog.flag &&
                      chatLog.flag.findIndex(
                        (item) => item === 'user-anger'
                      ) !== -1 && (
                        <SentimentVeryDissatisfiedIcon color="secondary" />
                      )}
                    {chatLog.flag &&
                      chatLog.flag.findIndex((item) => item === 'bot-anger') !==
                        -1 && <ReportIcon color="secondary" />}
                  </div>
                  <span
                    style={{
                      textAlign: 'right',
                      fontSize: 12,
                      color: 'gray',
                    }}
                  >
                    {getFullDateTimeString(new Date(
                        chatLog.date.$date
                      ))}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="col-9">
        <div
          style={{
            display: 'flex',
            marginBottom: 10,
            gap: 16,
          }}
        >
          <div
            style={{
              display: 'flex',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newVal) => setStartDate(newVal)}
                />
              </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newVal) => setEndDate(newVal)}
                />
              </DemoContainer>
            </LocalizationProvider>
            <Box
              sx={{
                height: '64px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={setFilterRangeToDefault}
            >
              <RestartAltIcon fontSize="large" />
            </Box>
          </div>
          {chatLogId && (
            <FormControlLabel
              control={
                <Checkbox checked={isEvaluated} onChange={handleChange} />
              }
              label="This is evaluated"
            />
          )}
          {chatLogId && (
            <FormControlLabel
              control={
                <Checkbox checked={hasShadowban} onChange={handleShadowbanToggle} />
              }
              label="Shadow ban"
            />
          )}
        </div>
        {chatLogId && (
        <Chatbox
          // readOnly={true}
          avatarName={chatLogId}
          appearedOn={
            selectedIndex !== -1
              ? filteredChatLogs[selectedIndex].appearedOn
              : ''
          }
          name={selectedIndex !== -1 ? chatLogs[selectedIndex].botName : ''}
          messages={messages}
          setMessages={setMessages}
          botId={selectedIndex !== -1 ? chatLogs[selectedIndex].botId : ''}
          chatbot={chatbot}
          logId={chatLogId}
          setTimeFlagArr={setTimeFlagArr}
          timeFlagArr={timeFlagArr}
          url={window.location.href}
        />)}
      </div>
    </div>
  );
};

export default ChatLogList;
