import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { PersistConfig, persistReducer, persistStore } from "redux-persist";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { Action } from "@reduxjs/toolkit";
import authReducer from "./Slice/authSlice";
import chatbotReducer from "./Slice/chatbotSlice";

const persistConfig: PersistConfig<any> = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  chatbot: chatbotReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Define the RootState type
type RootState = ReturnType<typeof persistedReducer>;

// Define the AppThunk type
type AppThunk<ReturnType = void> = ThunkMiddleware<
  ReturnType,
  RootState,
  Action<string>
>;

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk as AppThunk],
});

export const persistor = persistStore(store);