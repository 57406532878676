import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AddNewBotModal } from '../Modals/AddNewBotModal';
import { postWithAuthentication } from '../../Utils/FetchUtil';
import { convert_db_data } from '../../Utils/ChatbotUtil';
import { Link, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import {
  Switch,
  IconButton,
  TextField,
  InputAdornment,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import { useDispatch } from 'react-redux';
import {
  addChatBot,
  deleteChatbot,
} from '../../Slice/chatbotSlice';
import { Button } from 'react-bootstrap';
import { EmbedToWebsiteModal } from '../Modals/EmbedToWebsiteModal';

const ChatbotsTable: React.FC = () => {
  // const data = useSelector((auth) => auth.chatbot.chatbots);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [addNewBotModalShow, setAddNewBotModalShow] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [embedModalopen, setEmbedModalOpen] = useState(false);

  useEffect(() => {
    postWithAuthentication('chatbot/find-all-chatbots', {}, (result: string) => {
      setData(JSON.parse(result).map((d: any) => convert_db_data(d)));
    });
  }, [dispatch]);

  const handleClickOpenEmbedModal = () => {
    setEmbedModalOpen(true);
  };
  const handleCloseEmbedModal = () => {
    setEmbedModalOpen(false);
  };

  const filteredData = useMemo(
    () => data.filter((d) => d.name.includes(searchValue)),
    [data, searchValue]
  );

  const handleAdd = useCallback(() => {
    setAddNewBotModalShow(true);
  }, []);

  const AddNewData = useCallback(
    (newBot: any) => {
      dispatch(addChatBot(newBot));
      setData([...data, newBot])
    },
    [data, dispatch]
  );

  const handleRemoveChatbot = useCallback(() => {
    if (window.confirm('Are you sure want to delete?')) {
      postWithAuthentication(
        'chatbot/remove-chatbot',
        {
          body: JSON.stringify({
            id: filteredData[currentIndex]._id,
          }),
        },
        () => {
          dispatch(deleteChatbot(filteredData[currentIndex]._id));
          setData([...data.filter((bot) => filteredData[currentIndex]._id !== bot._id)])
          setCurrentIndex(-1);
        }
      );
    }
  }, [filteredData, currentIndex, dispatch, data]);

  const toggleActivate = useCallback(
    (index: string | number) => {
      postWithAuthentication(
        'chatbot/toggle-active',
        {
          body: JSON.stringify({
            id: filteredData[index]._id,
            active: !filteredData[index].active,
          }),
        },
        () => {
          const ind = data.findIndex((d) => d._id === filteredData[index]._id);
          setData([
            ...data.slice(0, ind),
            {
              ...data[ind],
              active: !filteredData[index].active,
            },
            ...data.slice(ind + 1),
          ]);
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, filteredData]
  );

  const handleViewChatlog = (bot) => {
    navigate(`chatbot/${bot._id}/chat-log`)
  }

  return (
    <>
      <EmbedToWebsiteModal
        id={
          filteredData[currentIndex] === undefined
            ? ''
            : filteredData[currentIndex]._id
        }
        open={embedModalopen}
        handleClose={handleCloseEmbedModal}
      />
      <div className="mt-4">
        <div className="" style={{ width: '98%' }}>
          <div className="col-12 d-flex justify-content-between">
            <h2 className="title mx-4">All Chats</h2>
            <button className="btn btn-primary" onClick={handleAdd}>
              <AddIcon />
              Add New
            </button>
          </div>
        </div>
        <div className="">
          <div
            className="search-bot col-3 mt-2 mx-3"
            style={{ textAlign: 'left' }}
          >
            <TextField
              style={{ textAlign: 'center' }}
              id="input-with-icon-textfield"
              variant="outlined"
              size="small"
              placeholder="Filter by chatbot name"
              value={searchValue}
              onChange={(ev) => setSearchValue(ev.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className="mt-2" style={{ width: '98%' }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Automatic Popup Delay Time</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell width={250}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((bot, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Switch
                        checked={bot.active}
                        onChange={(ev) => toggleActivate(index)}
                      />
                    </TableCell>
                    <TableCell>
                      <Link to={`/chatbot/${bot._id}/simulation`}>
                        <h3 className="fs-6">{bot.name}</h3>
                      </Link>
                    </TableCell>
                    <TableCell>{bot?.delayTime} secs</TableCell>
                    <TableCell>{moment(bot.createdAt).fromNow()}</TableCell>
                    <TableCell sx={{
                      display: "flex"
                    }}>
                      <Button
                        variant="outlined"
                        size="sm"
                        style={{ width: '150px' }}
                        onClick={() => handleViewChatlog(bot)}
                      >
                        View Chatlog
                      </Button>
                      <IconButton
                        aria-label="delete"
                        onClick={(ev) => {
                          setAnchorEl(ev.currentTarget);
                          setCurrentIndex(index);
                        }}
                      >
                        <ListIcon />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <Link to={`/chatbot/${bot._id}/setting`}>
                          <MenuItem onClick={() => setAnchorEl(null)}>
                            Settings
                          </MenuItem>
                        </Link>
                        <MenuItem
                          onClick={() => {
                            handleClickOpenEmbedModal();
                          }}
                        >
                          Embed to Website
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleRemoveChatbot();
                            setAnchorEl(null);
                          }}
                        >
                          Remove
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <AddNewBotModal
        show={addNewBotModalShow}
        setShow={setAddNewBotModalShow}
        handleAdd={AddNewData}
      />
    </>
  );
};
export default ChatbotsTable;
