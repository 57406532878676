import React, { useEffect, useState } from "react";
import AnalyticsCard from "./AnalyticsCard";
import { postWithAuthentication } from "../../Utils/FetchUtil";
import { FilterOptionProp } from "../../Pages/Analytics";
interface TotalSessionCountCardProps {
  botId: string,
  filterOption: FilterOptionProp
}

const TotalSessionCountCard: React.FC<TotalSessionCountCardProps> = ({ botId, filterOption }) => {
  const [val, setVal] = useState<number>(0);
  useEffect(() => {
    if (botId === null) return;
    postWithAuthentication(
      "analytics/total-chat-sessions-count",
      {
        body: JSON.stringify({
          id: botId,
          ...filterOption
        }),
      },
      (result) => setVal(result)
    );
  }, [botId, filterOption]);
  return (
    <AnalyticsCard text="Total Chat Session" val={val} icon="fa-message" />
  );
};

export default TotalSessionCountCard;


