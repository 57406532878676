import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
} from '@mui/material';
import {
  SendRequestAPIWithTokenByMethod,
} from '../Utils/FetchUtil';
import { useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { StringModal } from '../Components/Modals/StringModal';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import StringsData from '../Constants/strings.json';
import moment from 'moment';

const Strings = () => {
  const { chatbotId } = useParams();
  const headers = ['No', 'StringPoolId', 'TestId', 'Body', 'Retired', 'Weight', 'TimeStamp'];
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [isNew, setIsNew] = useState(false)
  const [initValue, setInitValue] = useState<any>({})
  const [selectedTestId, setSelectedTestId] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getStringsData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    setIsNew(true)
    setModalShow(true)
  }

  const handleEdit = (row) => {
    setIsNew(false)
    setModalShow(true)
    setInitValue(row)
    setSelectedTestId(row["TestId"])
  }

  const getStringsData = () => {
    setIsLoading(true)
    SendRequestAPIWithTokenByMethod('GET', `string/${chatbotId}`, null, (result) => {
      const parsedResult = JSON.parse(result);
      setData(
        parsedResult.map((item, index) => {
          const ind = StringsData.findIndex((string) => {
            return item['stringPoolId'] === string.id
          })
          return ({
            StringPoolId: ind === -1 ? item["stringPoolId"] : StringsData[ind].displayName,
            TestId: item['testId'],
            Body: item['body'],
            Retired: item['retired'],
            Weight: item['weight'],
            TimeStamp: new Date(item["timestamp"].$date)
          })
        }).sort((item1, item2) => {
          if (item1["Retired"] !== item2["Retired"]) {
            if (item1["Retired"]) return 1
            else return -1
          }
          if (item1["StringPoolId"] > item2["StringPoolId"])
            return 1
          if (item1["StringPoolId"] < item2["StringPoolId"])
            return -1
          const date1 = new Date(item1["TimeStamp"])
          const date2 = new Date(item2["TimeStamp"])
          if (date1 > date2)
            return -1
          return 1
        }).map((item, index) => ({...item, No: index + 1}))
      );
      setIsLoading(false)
    }, () => {
      setIsLoading(false)
    });
  }

  return (
    <Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <StringModal show={modalShow} setShow={setModalShow} isNew={isNew} handleGet={getStringsData} initValue={initValue} id={selectedTestId}/>
      <Box display="flex" justifyContent="flex-end">
        <Button
          size="small"
          sx={{ width: '100px' }}
          variant="outlined"
          onClick={handleAdd}
        >
          Add
        </Button>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          mt: 3,
        }}
      >
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row['TestId']}>
                {headers.map((header) => (
                  <TableCell
                    key={header}
                    sx={{ maxWidth: 200, overflowWrap: 'break-word', wordBreak: 'break-all' }}
                  >
                    {
                      header === "Retired"
                        ? (row[header] ? <CheckBoxIcon color='primary' /> : <CheckBoxOutlineBlankIcon color='primary' /> )
                        : header === "TimeStamp"
                        ? moment(row[header]).utc().format('MM/DD/YYYY HH:mm')
                        : typeof row[header] === 'string' && row[header].length > 120 ? `${row[header].slice(0, 120)}...` : row[header]
                    }
                  </TableCell>
                ))}
                <TableCell>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <IconButton onClick={() => handleEdit(row)} color='primary'><EditIcon /></IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default Strings;
