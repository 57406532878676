export const token_key: string = "jwt_token";

export const setToken = (token: string): void => {
  localStorage.setItem(token_key, token);
};

export const getToken = (): string | null => localStorage.getItem(token_key);

export const clearToken = (): void => {
  localStorage.removeItem(token_key);
};
