import React, { useEffect, useState } from "react";
import AnalyticsCard from "./AnalyticsCard";
import { postWithAuthentication } from "../../Utils/FetchUtil";
import { FilterOptionProp } from "../../Pages/Analytics";

interface SourceClickedCountCardProps {
  botId: string,
  filterOption: FilterOptionProp
}

const SourceClickedCountCard: React.FC<SourceClickedCountCardProps> = ({ botId, filterOption }) => {
  const [val, setVal] = useState<number>(0);
  useEffect(() => {
    if (botId === null) return;
    postWithAuthentication(
      "analytics/get-total-source-clicked",
      {
        body: JSON.stringify({
          id: botId,
          ...filterOption
        }),
      },
      (result) => setVal(result)
    );
  }, [botId, filterOption]);
  return (
    <AnalyticsCard text="Number of Source Clicked" val={val} icon="fa-hand-pointer" />
  );
};

export default SourceClickedCountCard;


