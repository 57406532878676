import React from 'react'
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

interface DeleteButtonProps {
  onClick: () => void;
  title?: string;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ onClick = () => {}, title = "Delete" }) => {
  return (
    <>
      <IconButton color="primary" onClick={onClick} title={title}>
        <DeleteIcon />
      </IconButton>
    </>
  );
};

export default DeleteButton;
