
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import { getFullUrl, getAuthorizationHeader } from '../Utils/FetchUtil'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { TextField } from '@mui/material'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material'
import { Box } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import ErrorIcon from '@mui/icons-material/Error'

interface TaggedString {
  text: string
  tag: string
}

interface Profile {
  paths: string[]
  automaticPopup: number | null
  coldOpenings: TaggedString[]
  bubbles: TaggedString[]
}

interface ChatBot {
  profiles: Profile[]
}

const NullChatbot = {
  profiles: []
}

function deepEqual(alfa: ChatBot, bravo: ChatBot): boolean {
  return JSON.stringify(alfa) === JSON.stringify(bravo)
}

export const PathProfiles = () => {
  const { chatbotId } = useParams()
  const [chatbot, setChatbot] = useState<ChatBot>(NullChatbot)
  const [savedChatbot, setSavedChatbot] = useState<ChatBot>(NullChatbot)
  const [profileIndex, setProfileIndex] = useState<number>(0)

  const hasChanges = !deepEqual(chatbot, savedChatbot)

  const profile: Profile | null = chatbot?.profiles[profileIndex] || null

  const patchChatbot = async (chatbot: ChatBot) => {
    await fetch(
      getFullUrl(`chatbot/${chatbotId}`),
      {
        method: 'PATCH',
        headers: {
          ...getAuthorizationHeader(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          profiles: chatbot.profiles
        })
      })
  }

  const save = async () => {
    if (chatbot) {
      await patchChatbot(chatbot)
      setSavedChatbot(chatbot)
    }
  }

  useEffect(
    () => {
      console.log(savedChatbot)
      setChatbot(savedChatbot)
    },
    [ savedChatbot ])

  useEffect(
    () => {
      (async () => {
        const response = await fetch(
          getFullUrl(`chatbot/${chatbotId}`),
          {
            headers: {
              ...getAuthorizationHeader(),
            }
          })
        const result = await response.json()
        setSavedChatbot(result)
      })()
    },
    [ chatbotId ])

  useEffect(
    () => {
      if (profileIndex >= chatbot?.profiles.length) {
        setProfileIndex(chatbot?.profiles.length - 1 || 0)
      }
      else if (profileIndex < 0) {
        setProfileIndex(0)
      }
    },
    [ profileIndex, chatbot?.profiles.length ])

  function parseIntOrNull(value: string): number | null {
    const result = parseInt(value)
    return isNaN(result) ? null : result
  }
  
  const setProfileIndexByKey = (key: string) => {
    const index = parseInt(key)
    if (!isNaN(index)) {
      setProfileIndex(index)
    }
  }

  const addProfile = () => {
    setChatbot({
      profiles: [
        ...chatbot?.profiles,
        {
          paths: [],
          automaticPopup: 5,
          coldOpenings: [],
          bubbles: []
        }
      ]
    })
    setProfileIndex(chatbot?.profiles.length || 0)
  }

  const deleteProfile = () => {
    if (chatbot) {
      const newProfiles = [
        ...chatbot.profiles.slice(0, profileIndex),
        ...chatbot.profiles.slice(profileIndex + 1)]
      setChatbot({
        ...chatbot,
        profiles: newProfiles
      })
    }
  }

  const updateCurrentProfile = (profile: Profile) => {
    if (chatbot) {
      setChatbot({
        profiles: [
          ...chatbot.profiles.slice(0, profileIndex),
          profile,
          ...chatbot.profiles.slice(profileIndex + 1)
        ]
      })
    }
  }

  const addPath = () => {
    if (profile) {
      updateCurrentProfile({
        ...profile,
        paths: [
          ...profile.paths,
          '/new/path'
        ]
      })
    }
  }

  const deletePath = (index: number) => {
    if (profile) {
      updateCurrentProfile({
        ...profile,
        paths: [
          ...profile.paths.slice(0, index),
          ...profile.paths.slice(index + 1)
        ]
      })
    }
  }

  const setPath = (index: number, path: string) => {
    if (profile) {
      updateCurrentProfile({
        ...profile,
        paths: [
          ...profile.paths.slice(0, index),
          path,
          ...profile.paths.slice(index + 1)
        ]
      })
    }
  }

  const setColdOpening = (index: number, coldOpening: TaggedString) => {
    if (profile) {
      updateCurrentProfile({
        ...profile,
        coldOpenings: [
          ...profile.coldOpenings.slice(0, index),
          coldOpening,
          ...profile.coldOpenings.slice(index + 1)
        ]
      })
    }
  }

  const addColdOpening = () => {
    if (profile) {
      updateCurrentProfile({
        ...profile,
        coldOpenings: [
          ...profile.coldOpenings,
          { text: 'Hi!', tag: '' }
        ]
      })
    }
  }

  const deleteColdOpening = (index: number) => {
    if (profile) {
      updateCurrentProfile({
        ...profile,
        coldOpenings: [
          ...profile.coldOpenings.slice(0, index),
          ...profile.coldOpenings.slice(index + 1)
        ]
      })
    }
  }

  const setBubble = (index: number, bubble: TaggedString) => {
    if (profile) {
      updateCurrentProfile({
        ...profile,
        bubbles: [
          ...profile.bubbles.slice(0, index),
          bubble,
          ...profile.bubbles.slice(index + 1)
        ]
      })
    }
  }

  const addBubble = () => {
    if (profile) {
      updateCurrentProfile({
        ...profile,
        bubbles: [
          ...profile.bubbles,
          { text: 'Hi!', tag: '' }
        ]
      })
    }
  }

  const deleteBubble = (index: number) => {
    if (profile) {
      updateCurrentProfile({
        ...profile,
        bubbles: [
          ...profile.bubbles.slice(0, index),
          ...profile.bubbles.slice(index + 1)
        ]
      })
    }
  }

  function profileName(profile: Profile, index: number): string {
    if (profile.paths.length > 1) {
      return profile.paths[0] + ', ...'
    }
    if (profile.paths.length === 1) {
      return profile.paths[0]
    }
    return `Profile ${index + 1}`
  }

  function evaluatePath(path: string, index: number): string | null {
    if (path === '') {
      return 'Path cannot be empty'
    }
    const allPaths = chatbot?.profiles.flatMap(profile => profile.paths)
    const matches = allPaths.filter(p => p === path)
    if (matches.length > 1) {
      return 'Path must be unique'
    }
    const regex = /^\/$|^\/[\w\-\/\*]*$/
    const isValid = regex.test(path)
    if (!isValid) {
      return 'Invalid Path'
    }
    return null
  }

  function RedAlertSquare(text: string) {
    return (
      <Tooltip title={text}>
      <Box
        sx={{
          width: 'auto',  // Adjusting to content
          height: 'auto', // Adjusting to content
          backgroundColor: 'red',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '0.25rem',
          padding: '0.5rem'  // Adding padding for better spacing
        }}
      >
        <ErrorIcon sx={{ color: 'white' }} />
      </Box>
      </Tooltip>
    )}

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
        <Dropdown onSelect={setProfileIndexByKey}>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {profile != null
              ? profileName(profile, profileIndex)
              : 'No Profile Selected'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {chatbot?.profiles.map((profile, index) => (
              <Dropdown.Item eventKey={index.toString()} key={index}>{profileName(profile, index)}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={addProfile}>Add</Button>
        {profile && (
          <Button
            variant="contained"
            color="primary"
            onClick={deleteProfile}>Delete</Button>)}
        {chatbot && (
          <Button
          variant="contained"
          color="primary"
          disabled={!hasChanges}
          onClick={save}>Save</Button>)}
      </div>
      <h2>Paths</h2>
      <p>Use an asterix * as a wildcard. For example, `/alfa/*` will match `/alfa/bravo`.</p>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{flex: 3}}>Path</TableCell>
              <TableCell sx={{flex: 0}} align='right'>Alert</TableCell>
              <TableCell sx={{flex: 0}} align='right'>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profile?.paths.map((path, index) => (
              <TableRow key={index}>
                <TableCell sx={{flex: 3}}>
                  <TextField
                    name="name"
                    value={path}
                    onChange={(e) => setPath(index, e.target.value)}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </TableCell>
                <TableCell sx={{flex: 0}}>
                  {evaluatePath(path, index) && RedAlertSquare(evaluatePath(path, index))}
                </TableCell>
                <TableCell sx={{flex: 0}} align='right'>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => deletePath(index)}>Delete</Button></TableCell>
              </TableRow>
            ))}
            {profile && (
              <TableRow>
                <TableCell colSpan={3} align='right'>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={addPath}>Add</Button>
                </TableCell>
              </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <h2>Automatic Popup</h2>
      <p>Time in seconds before the chatbot automatically pops up.</p>
      <p>If no number is given, no popup will occur.</p>
      <TextField
        name="name"
        value={profile?.automaticPopup || ''}
        onChange={(e) => updateCurrentProfile({ ...profile, automaticPopup: parseIntOrNull(e.target.value) })}
        type='number'
        variant="outlined"
        fullWidth
        size="small" />
      <br />
      <br />
      <h2>Cold Opening</h2>
      <p>If more than one cold opening is found, it will be chosen randomly for each conversation.</p>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{flex: 1}}>Text</TableCell>
              <TableCell sx={{flex: 1}}>Test Code</TableCell>
              <TableCell sx={{flex: 0}} align='right'>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profile?.coldOpenings.map((coldOpening, index) => (
              <TableRow key={index}>
                <TableCell sx={{flex: 1}}>
                  <TextField
                    name="name"
                    value={coldOpening.text}
                    onChange={(e) => setColdOpening(index, { text: e.target.value, tag: coldOpening.tag })}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </TableCell>
                <TableCell sx={{flex: 1}} align='right'>
                  <TextField
                    name="name"
                    value={coldOpening.tag}
                    onChange={(e) => setColdOpening(index, { text: coldOpening.text, tag: e.target.value })}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </TableCell>
                <TableCell sx={{flex: 0}} align='right'>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => deleteColdOpening(index)}>Delete</Button></TableCell>
              </TableRow>
            ))}
            {profile && (
              <TableRow>
                <TableCell colSpan={3} align='right'>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={addColdOpening}>Add</Button>
                </TableCell>
              </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <h2>Bubbles</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{flex: 1}}>Text</TableCell>
              <TableCell sx={{flex: 1}}>Test Code</TableCell>
              <TableCell sx={{flex: 0}} align='right'>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profile?.bubbles.map((bubble, index) => (
              <TableRow key={index}>
                <TableCell sx={{flex: 1}}>
                  <TextField
                    name="name"
                    value={bubble.text}
                    onChange={(e) => setBubble(index, { text: e.target.value, tag: bubble.tag })}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </TableCell>
                <TableCell sx={{flex: 1}}>
                  <TextField
                    name="name"
                    value={bubble.tag}
                    onChange={(e) => setBubble(index, { text: bubble.text, tag: e.target.value })}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </TableCell>
                <TableCell sx={{flex: 0}} align='right'>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => deleteBubble(index)}>Delete</Button></TableCell>
              </TableRow>
            ))}
            {profile && (
              <TableRow>
                <TableCell colSpan={3} align='right'>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={addBubble}>Add</Button>
                </TableCell>
              </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default PathProfiles