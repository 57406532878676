import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Analytics from "./Analytics";
import ChatIcon from "@mui/icons-material/Chat";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SettingsIcon from "@mui/icons-material/Settings";
import PathsIcon from '@mui/icons-material/DatasetLinked';
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import SubjectIcon from '@mui/icons-material/Subject';
import Chatbot from "./Chatbot";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import ChatLogList from "./ChatLogList";
import { Settings } from "./Settings";
import { Badge, Grid, Typography } from "@mui/material";
import Tests from "./Tests"
import { postWithAuthentication } from "../Utils/FetchUtil";
import Strings from "./Strings";
import PathProfiles from "./PathProfiles";

const ChatbotTabs: React.FC = () => {
  const isInframe = window.self !== window.top;
  const { chatbotId, tab } = useParams();
  const navigate = useNavigate();
  const [chatCount, setChatCount] = useState<number>(0);
  const [chatlogId, setChatLogId] = useState<string>(uuidv4().toString());

  const handleChange = (event, newValue) => {
    setChatLogId(uuidv4().toString());
    navigate(`/chatbot/${chatbotId}/${newValue}`);
  };

  useEffect(() => {
    if (!isInframe) {
      postWithAuthentication(
        "chatlog/find-chatlog-counts",
        {
          body: JSON.stringify({
            id: chatbotId,
          }),
        },
        (result) => {
          setChatCount(result);
        }
      );
    }
  }, [chatbotId, isInframe]);

  return (
    <Grid >
      <Box sx={{
        position: "sticky",
        top: 0,
        backgroundColor: 'white',
        zIndex: 1000
      }}>
        {!isInframe && (
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tab} onChange={handleChange}
              sx={{
                '& .MuiTabs-flexContainer': {
                  flexWrap: 'wrap',
                },
              }}
            >
              <Tab
                icon={<ChatIcon />}
                iconPosition="start"
                label="Simulation"
                value="simulation"
              />
              <Tab
                icon={<SettingsIcon />}
                iconPosition="start"
                label="Bot Settings"
                value="setting"
              />
              <Tab
                icon={<PathsIcon />}
                iconPosition="start"
                label="Path Profiles"
                value="pathProfiles"
              />
              <Tab
                icon={<AnalyticsIcon />}
                iconPosition="start"
                label="Analytics"
                value="analysis"
              />
              <Tab
                icon={<ManageSearchIcon />}
                iconPosition="start"
                label={
                  <Badge color="error" badgeContent={chatCount} max={9}>
                    <Typography fontSize="0.875rem">Chat Logs</Typography>
                  </Badge>
                }
                value="chat-log"
              />
              <Tab
                icon={<TextSnippetIcon />}
                iconPosition="start"
                label="Tests"
                value="tests"
              />
              <Tab
                icon={<SubjectIcon />}
                iconPosition="start"
                label="Strings"
                value="strings"
              />
            </Tabs>
          </Box>
        )}
      </Box>
      <Box padding={4}>
        {tab === "simulation" && <Chatbot chatLogId={chatlogId}/>}
        {tab === "setting" && <Settings />}
        {tab === "pathProfiles" && <PathProfiles />}
        {tab === "analysis" && <Analytics />}
        {tab === "chat-log" && <ChatLogList />}
        {tab === "tests" && <Tests />}
        {tab === "strings" && <Strings />}
      </Box>
    </Grid>
  );
};

export default ChatbotTabs;
