import { getToken } from './AuthUtil';

export const getFullUrl = (url) => process.env.REACT_APP_API_HOST + url;

export function getAuthorizationHeader(): HeadersInit {
  const token = getToken()
  return {
    Authorization: `Bearer ${token}`
  }
}

export function getRequestInit() : RequestInit {
  const token = getToken()
  return {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  }}

interface IResponse {
  code: number;
  text: string;
}

export const SendRequestAPIWithTokenByMethod = (
  method,
  url,
  config = null,
  resolve = null,
  reject = null
) => {
  const token = getToken()
  return fetch(getFullUrl(url), {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    ...config
  }).then(async (response) => {
    if (response.status === 200) {
      resolve(await response.json())
    } else {
      reject && reject()
    }
  })
}

export const postWithAuthentication = async (
  url: string,
  config: RequestInit,
  resolve?: (value: any) => void,
  reject?: (reason: IResponse) => void,
  failSilently: boolean = false
) => {
  try
  {
    const response = await fetch(getFullUrl(url), {
      ...getRequestInit(),
      ...config,
    })
    const result = await response.json()
    const wrappedAlert = failSilently ? () => {} : alert;
    if (response.ok && resolve) {
      resolve(result)
    }
    else if (response.status === 401 || response.status === 402 || response.status === 409) {
      wrappedAlert(result.detail)
    } else if (!response.ok) {
      wrappedAlert('Failed')
      console.log(`${response.status} error`, result.detail)
    }
    if (reject) {
      reject(result.detail)
    }
  }
  catch (error)
  {
    console.log(`request error`, error)
    if (reject) {
      reject(error)
    }
  }
};

export const SendUploadRequestWithToken_test = (
  url: string,
  config: RequestInit,
  resolve?: (value: any) => void,
  reject?: (reason: IResponse) => void
) => {
  const token = getToken();
  fetch(getFullUrl(url), {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    ...config,
  })
    .then(async (response) => {
      const result = await response.json();
      if (response.ok) {
        resolve(result);
      } else {
        if (response.status === 401 || response.status === 402) {
          alert(result.detail);
        } else {
          alert('Failed');
          console.log(`${response.status} error`, result.detail);
        }
        reject(result.detail);
      }
    })
    .catch((error) => {
      console.log(`request error`, error);
      reject(error);
    });
};

export const getProviders = async (): Promise<any> => {
  let res = await fetch(getFullUrl('chatbot/get-providers'), {
    method: 'POST',
  });

  res = await res.json();

  return res;
};
