import { IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import React from "react";

interface ActivateButtonProps {
  onClick: () => {};
  title?: string;
}

const ActivateButton: React.FC<ActivateButtonProps> = ({ onClick = () => {}, title = "Activate Key" }) => {
  return (
    <>
      <IconButton color="primary" onClick={onClick} title={title}>
        <CheckIcon />
      </IconButton>
    </>
  );
};

export default ActivateButton;
